/* eslint-disable no-unused-vars */
interface IElectron {
  shell: {
    openExternal(url: string): void;
  };
  ipcRenderer: {
    send(name: string, payload?: any): void;
    on(name: string, callback: (event: object, payload: any) => void): void;
    removeListener(
      name: string,
      callback: (event: object, payload: any) => void
    ): void;
  };
}

export function Electron(): IElectron {
  return require("electron");
}
