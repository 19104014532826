import {
  createRouter,
  createWebHashHistory,
  createWebHistory
} from "vue-router";

import { LSNames } from "@/common/constants";
import { authPageMiddleware } from "@/middleware/auth";
import { finalMiddleware } from "@/middleware/common/final-middleware";
import { contactsPageMiddleware } from "@/middleware/contacts";
import { homepageMiddleware } from "@/middleware/homepage";
import { meetMiddleware } from "@/middleware/meet";
import { meetsPageMiddleware } from "@/middleware/meets";
import { onboardingMiddleware } from "@/middleware/onboarding";
import { redirectMiddleware } from "@/middleware/redirect";
import { serviceMiddleware } from "@/middleware/service";
import { servicesMiddleware } from "@/middleware/services";
import { MainService } from "@/service";

const Homepage = () => import("@/views/homepage/homepage.vue");
const Auth = () => import("@/views/auth/auth.vue");
const Services = () => import("@/views/services/services.vue");
const Redirect = () => import("@/views/redirect/redirect.vue");
const Onboarding = () => import("@/views/onboarding/onboarding");
const ChatList = () => import("@/views/chat-list/chat-list");
const ChatPage = () => import("@/views/chat-page/chat-page");
const Page404 = () => import("@/views/page-404/page-404");
const CreateChatPage = () =>
  import("@/views/create-chat-page/create-chat-page");
const UserPage = () => import("@/views/user/user");
const ChatInfoPage = () => import("@/views/chat-info-page/chat-info-page");
const Contacts = () => import("@/views/contacts/contacts");
const Meet = () => import("@/views/meet/meet");
const Error = () => import("@/views/error/error.vue");
const Meets = () => import("@/views/meets/meets");
const EmptyComponent = () => import("@/common/empty.vue");
const ProfilePage = () => import("@/views/profile/profile.vue");
const SettingsPage = () => import("@/views/settings/settings.vue");

const service = new MainService();
const isBrowser = service.isBrowser();

const router = createRouter({
  history: isBrowser ? createWebHistory() : createWebHashHistory(),
  routes: [
    {
      path: isBrowser ? "/" : "/index.html",
      props: route => ({ route }),
      beforeEnter: () => {
        if (
          !location.pathname.startsWith("/redirect") &&
          !location.pathname.startsWith("/error") &&
          !location.pathname.startsWith("/auth") &&
          location.pathname !== "/" &&
          service.isBrowser()
        ) {
          window.initPath = location.pathname + location.search;
        }
      },
      children: [
        {
          name: "index",
          path: "/",
          beforeEnter: (to, from, next) => {
            service.setPageTitle("Главная");
            homepageMiddleware(to, from, next);
          },
          component: Homepage,
          children: [
            {
              name: "services",
              path: "/services",
              beforeEnter: (to, from, next) => {
                service.setPageTitle("Сервисы");
                servicesMiddleware(to, from, next);
              },
              component: Services
            },
            {
              name: "app",
              path: "/app/:id",
              beforeEnter: (to, from, next) => {
                serviceMiddleware(to, from, next);
              },
              component: EmptyComponent
            },
            {
              name: "chat-list",
              path: "/chat",
              component: ChatList,
              beforeEnter: () => {
                service.setPageTitle("Чаты");
              },
              children: [
                {
                  name: "chat-page",
                  path: "/chat/:id",
                  component: ChatPage,
                  children: [
                    {
                      name: "chat-info",
                      path: "/chat/:id/info",
                      component: ChatInfoPage
                    }
                  ]
                },
                {
                  name: "create-chat",
                  path: "/chat/create/:kind",
                  component: CreateChatPage
                },
                {
                  name: "chat-user-page",
                  path: "/user-info/:id",
                  component: UserPage
                }
              ]
            },
            {
              name: "contacts",
              path: "contacts",
              component: Contacts,
              beforeEnter: (to, from, next) => {
                service.setPageTitle("Контакты");
                contactsPageMiddleware(to, from, next);
              },
              children: [
                {
                  name: "user-page",
                  path: "/user/:id",
                  component: UserPage
                }
              ]
            },
            {
              name: "profile",
              path: "/profile",
              component: ProfilePage,
              children: [
                {
                  name: "profile-page",
                  path: "/profile/user",
                  component: UserPage
                },
                {
                  name: "settings",
                  path: "/profile/settings",
                  component: SettingsPage
                }
              ]
            },
            {
              name: "meets-blank",
              path: "meets",
              component: Meets,
              beforeEnter: (to, from, next) => {
                service.setPageTitle("Встречи");
                meetsPageMiddleware(to, from, next);
              }
            },
            {
              name: "meets",
              path: "meets/:page?/:meetId?",
              component: Meets,
              beforeEnter: (to, from, next) => {
                service.setPageTitle("Встречи");
                meetsPageMiddleware(to, from, next);
              }
            }
          ]
        },
        {
          name: "redirect",
          path: "/redirect",
          beforeEnter: (to, from, next) => {
            service.setPageTitle("Авторизация");
            redirectMiddleware(to, from, next);
          },
          component: Redirect
        },
        {
          name: "onboarding",
          path: "/onboarding",
          beforeEnter: (to, from, next) => {
            service.setPageTitle("Добро пожаловать");
            onboardingMiddleware(to, from, next);
          },
          component: Onboarding
        },
        {
          name: "auth",
          path: "/auth",
          beforeEnter: (to, from, next) => {
            service.setPageTitle("Авторизация");
            authPageMiddleware(to, from, next);
          },
          component: Auth
        },
        {
          name: "meet",
          path: "/meet/:roomID",
          beforeEnter: (to, from, next) => {
            meetMiddleware(to, from, next);
          },
          component: Meet
        }
      ]
    },
    {
      name: "error",
      path: "/error",
      component: Error
    },
    {
      name: "404",
      path: "/:pathMatch(.*)*",
      beforeEnter: (_, __, next) => {
        service.setPageTitle("404");
        finalMiddleware();
        next();
      },
      component: Page404
    }
  ]
});

router.beforeEach(to => {
  const store = require("@/store").default;

  if (store) {
    store.commit("getMobile");
  }
  if (localStorage.getItem(LSNames.Dev)) {
    // eslint-disable-next-line no-console
    console.log(
      `%c${new Date().toLocaleString()}: %cNavigation%c Change %c${to.fullPath}`,
      "color: initial;font-style: italic",
      "color: #49a1ff;font-weight: bold",
      "color: initial;font-weight: bold",
      "color: #e11b11;font-weight: bold"
    );
  }
});

export default router;
