import { LSNames } from "@/common/constants";

export default () => {
  if (!window.BroadcastChannel) return;
  const tokenWorkerBC = new BroadcastChannel("token-worker");

  tokenWorkerBC.addEventListener("message", event => {
    const isBusy = !!localStorage.getItem(LSNames.TokenWorkerBusy);

    try {
      const data: {
        id: string;
        type: "refresh-request" | "refresh-end-request";
        tokens?: { access: string; refresh: string };
        debug?: boolean;
      } = event.data;

      if (data.id) {
        if (data.type === "refresh-request") {
          if (isBusy) {
            tokenWorkerBC.postMessage({
              type: "refresh-response",
              id: data.id,
              status: "busy"
            });
          } else {
            localStorage.setItem(LSNames.TokenWorkerBusy, "1");
            tokenWorkerBC.postMessage({
              type: "refresh-response",
              id: data.id,
              status: "became-busy"
            });
          }
        } else if (data.type === "refresh-end-request" && data.tokens) {
          tokenWorkerBC.postMessage({
            type: "refresh-end-response",
            id: data.id,
            tokens: data.tokens
          });
          localStorage.removeItem(LSNames.TokenWorkerBusy);
        }
      }
    } catch {}
  });
};
