import { inject } from "vue";

import { MainService, StreamService } from "@/service";
import { EnvironmentService } from "@/service/options";

export const useService = (): {
  $service: MainService;
  $streamService: StreamService;
  $environmentService: EnvironmentService;
} => {
  return {
    $service: inject("service") as MainService,
    $streamService: inject("streamService") as StreamService,
    $environmentService: inject("environmentService") as EnvironmentService
  };
};
