<template>
  <header class="header">
    <div class="header__user-container">
      <template v-if="user">
        <Avatar
          :id="user.avatar?.id"
          ref="avatarElement"
          :link="user.avatar?.url"
          :name="user.renderName"
          class="header__user-image"
          is-chat
          @click="openCurrentUser"
        />
        <p v-if="user.firstName" class="header__user-greeting-text">
          Здравствуйте, {{ user.firstName }}!
        </p>
      </template>
    </div>
    <div class="header__navigation-container">
      <TNSearch
        v-model="searchString"
        search-hint="Введите 3 символа, чтобы начать поиск"
        :size="isMobile ? 's' : 'm'"
        class="header__search-input"
        :loading="isSearchLoading"
        placeholder="Поиск по TN Life"
        :show-result="showSearchResult"
        :result="resultList"
        :result-max-height="0"
        nothing-found-title="Ничего не найдено"
        nothing-found-description="Попробуйте изменить поисковый запрос"
        nothing-found-badge
        cell
        select
        group-title-button="Показать больше"
        :show-group-titles="groupView"
        @input="queryUpdateHandler(false)"
        @focus="focusSearchHandler"
        @blur="blurSearchHandler"
        @select="searchItemClick"
        @click:groupTitle="groupTitleClickHandler"
      />
      <div class="header__search-background" @click="closeSearch"></div>
      <TNButton
        icon="help"
        white
        size="md"
        class="header__help-button"
        @click="openHelpMenu"
      />
      <DropdownMenu
        top="32"
        right="0"
        :show-menu="isHelpMenuVisible"
        :menu-items="helpMenuItems"
        button-class-name="header__help-button"
        @select="helpSelectHandler"
        @close="closeHelpMenu"
      />
    </div>
  </header>
</template>

<script lang="ts" src="./header.ts"></script>

<style src="./header.css"></style>
