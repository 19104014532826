import { ICellDataItem } from "@life_uikit/uikit/interfaces";

/* eslint-disable no-unused-vars */
export enum ResultType {
  Contact,
  Employee,
  Channel,
  Group,
  Message,
  Service
}

export interface ICustomCellDataItem extends ICellDataItem {
  type: ResultType;
  messageId?: string;
  streamId?: string;
  id: string;
}

export type GroupView =
  | "Мои контакты"
  | "Телефонная книга"
  | "Каналы"
  | "Групповые чаты"
  | "Сообщения в публичных каналах"
  | "Сервисы";
