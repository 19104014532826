import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "navigation-item__icon"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TNIcon = _resolveComponent("TNIcon")!
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["navigation-item", {
      'navigation-item_button':
        _ctx.navigation && (_ctx.navigation.path || _ctx.navigation.click),
      'navigation-item_active': _ctx.active,
      'navigation-item_background-run': _ctx.background,
      'navigation-item_background-active': _ctx.backgroundActive,
      'navigation-item_notification': _ctx.notification?.count,
      'navigation-item_system': _ctx.system,
      'navigation-item_avatar': _ctx.navigation.image && !_ctx.navigation.iconName
    }])
  }, [
    (_ctx.navigation.iconName)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_TNIcon, {
            name: _ctx.navigation.iconName
          }, null, 8 /* PROPS */, ["name"])
        ]))
      : (_ctx.navigation.image || _ctx.navigation.imageName)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_Avatar, {
              class: "navigation-item__avatar",
              link: _ctx.navigation.image,
              name: _ctx.navigation.imageName
            }, null, 8 /* PROPS */, ["link", "name"])
          ]))
        : _createCommentVNode("v-if", true),
    _createElementVNode("p", {
      class: "navigation-item__text",
      innerHTML: _ctx.navigation.name
    }, null, 8 /* PROPS */, _hoisted_3),
    _createVNode(_Transition, { name: "scale" }, {
      default: _withCtx(() => [
        (_ctx.notification && _ctx.notification.count > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["navigation-item__notification-counter", {
          'navigation-item__notification-counter_only-muted':
            _ctx.notification?.muted
        }])
            }, _toDisplayString(_ctx.notification && _ctx.notification.count < 10 ? _ctx.notification.count : "9+"), 3 /* TEXT, CLASS */))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ], 2 /* CLASS */))
}