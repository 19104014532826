import { LSNames } from "@/common/constants";
import { GrpcConfig } from "@/infrastructure/request-params";

export class EnvironmentService {
  environmentOptions(): {
    dynLinkOrigins: string[];
    webAppLink: string;
    appLink: string;
  } {
    const dynLinkOrigins = (
      localStorage.getItem(LSNames.DynLinkOrigins) || ""
    ).split(",");
    const webAppLink = GrpcConfig().backendUrl.match(/^https:\/\/api-.*/)
      ? GrpcConfig().backendUrl.replace(/^https:\/\/api-/, "https://web-")
      : GrpcConfig().backendUrl.match(/^https:\/\/api\..*/)
        ? GrpcConfig().backendUrl.replace(/^https:\/\/api\./, "https://web.")
        : "";
    const appLink = GrpcConfig().backendUrl.match(/^https:\/\/api-.*/)
      ? GrpcConfig().backendUrl.replace(/^https:\/\/api-/, "https://app-")
      : GrpcConfig().backendUrl.match(/^https:\/\/api\..*/)
        ? GrpcConfig().backendUrl.replace(/^https:\/\/api\./, "https://app.")
        : "";

    return {
      dynLinkOrigins,
      webAppLink,
      appLink
    };
  }
}
