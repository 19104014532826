import { LSNames } from "@/common/constants";

export function GrpcConfig() {
  const { MainService } = require("@/service");
  const service = new MainService();

  let backendUrl: string;

  if (service.isBrowser() && window.VUE_APP_API_URL) {
    backendUrl = window.VUE_APP_API_URL;
  } else {
    const env = service.getEnvironment();

    backendUrl = env ? env.baseApiEndpoint : "";
  }

  const accessToken = localStorage.getItem(LSNames.AT);
  const refreshToken = localStorage.getItem(LSNames.RT);

  return {
    metadata: {
      Authorization: "Bearer " + accessToken
    },
    refreshMetadata: {
      Authorization: "Bearer " + refreshToken
    },
    backendUrl
  };
}
