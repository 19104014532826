<template>
  <Popup
    :visible="!!form"
    :popup="popup"
    :full-height="isMobile"
    :custom-class="['report-form', isFlattened ? 'report-form_success' : '']"
    @close="closeWindow"
  >
    <div v-if="isSend" class="report-form__success-window">
      <div class="report-form__success-icon">
        <transition name="bounce">
          <div class="report-form__success-check-icon">
            <TNIcon name="check" />
          </div>
        </transition>
      </div>
      <h4 class="report-form__success-title" v-html="successTitle"></h4>
      <p class="report-form__success-annotation" v-html="successAnnotation"></p>
    </div>
    <div v-else class="report-form__window">
      <div class="report-form__form-item-container">
        <TNSelector
          label="Тип обращения"
          chip-titles
          class="report-form__selector"
          placeholder="Не выбран"
          :multiple="false"
          :clearable="false"
          :model-value="selectedType"
          :options="typeList"
          :disabled="isTypeSelectorDisabled"
          @update:modelValue="updateTypeValue"
        />
      </div>
      <div class="report-form__form-item-container">
        <TNTextarea
          v-model="reportBody"
          label="Обращение"
          placeholder="Распишите свою проблему или предложение"
          class="report-form__textarea"
        />
      </div>
    </div>
  </Popup>
</template>

<script lang="ts" src="./report-form.ts" />

<style lang="css" src="./report-form.css" />
