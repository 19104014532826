<template>
  <TNBottomSheet
    :is-open="visible"
    :header="popupHeader"
    :lock-swipe="lockSwipe"
    is-mobile-mini-app
    :full-height="fullHeight"
    :custom-class="customClassList"
    @hide="closePopup"
  >
    <template #header>
      <slot name="header"></slot>
    </template>
    <template #default>
      <div v-if="popup.body" v-html="popup.body"></div>
      <slot></slot>
      <div
        v-if="resizable"
        class="popup__resize-handle"
        @mousedown="starResizing"
      ></div>
    </template>
    <template #footer>
      <slot name="footer"></slot>
      <div
        v-if="popup.buttons && popup.buttons.length"
        class="popup__button-container"
        :class="
          'popup__button-container_' + (popup.buttonsLayout || 'horizontal')
        "
      >
        <TNButton
          v-for="button in popup.buttons"
          ref="buttons"
          :key="button.text"
          class="popup__button"
          :icon="button.icon"
          :outline="button.type === 'outline'"
          :white="button.type === 'link'"
          :secondary="button.type === 'secondary'"
          :disabled="isButtonsLoading || button.disabled"
          :loading="button.loading"
          size="lg"
          :style="{
            flexBasis:
              popup.buttonsLayout === 'horizontal' || !popup.buttonsLayout
                ? 'calc(' + 100 / popup.buttons?.length + '% - 16px)'
                : ''
          }"
          @click="buttonClickHandler(button)"
        >
          {{ button.text }}
        </TNButton>
      </div>
    </template>
  </TNBottomSheet>
</template>

<script lang="ts" src="./popup.ts" />

<style lang="css" src="./popup.css" />
