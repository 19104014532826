import TNLifeUIKit from "@life_uikit/uikit";
import { createApp } from "vue";
import { ObserveVisibility } from "vue-observe-visibility";
import VueYandexMetrika from "vue3-yandex-metrika";

import { SSNames } from "@/common/constants";
import { Electron } from "@/infrastructure/electron";
import layout from "@/layouts/layout.vue";
import router from "@/router";
import "@/assets/css/app.css";
import servicePlugin, { MainService } from "@/service";
import { ExternalAPI } from "@/service/external_api";
import store, { key } from "@/store";

import "cropperjs/dist/cropper.css";
import "@life_uikit/uikit/variables.css";
import "@life_uikit/uikit/fonts.css";

import infraLayer from "./infrastructure/vue-plugin";
import initTokenWorker from "./token-worker";

const $service = new MainService();
const app = createApp(layout);
const isBrowser = $service.isBrowser();
const serviceWorkerID = Date.now();

sessionStorage.setItem(SSNames.TWID, serviceWorkerID.toString());

if (!isBrowser) {
  Electron().ipcRenderer.on("logout", async () => {
    $service.logout();
  });

  new ExternalAPI().init();
  $service.checkEnvironment();
} else {
  initTokenWorker();
  if (window.VUE_APP_YANDEX_METRIKA_ID) {
    setTimeout(() => {
      app.use(VueYandexMetrika, {
        id: window.VUE_APP_YANDEX_METRIKA_ID,
        router,
        env: "production",
        options: {
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true
        }
      });
    }, 300);
  }
}
// dark theme
$service.applyDarkTheme();
$service.applyBlurMode();

document.body
  .querySelector(".body__splash-container")
  ?.addEventListener("transitionend", ev => {
    const target = ev.target as HTMLElement;
    const body = document.body;

    setTimeout(() => {
      if (document.body.classList.contains("body-loading")) {
        document.body.classList.add("body-loading_loaded");
      }
    }, 30000);
    if (target.classList.contains("body__name")) {
      if (body.classList.contains("body-loading_hide")) {
        body.classList.remove("body-loading_loaded");
        body.classList.remove("body-loading_hide");
        body.classList.remove("body-loading");
      } else if (body.classList.contains("body-loading_loaded")) {
        body.classList.add("body-loading_hide");
      }
    }
  });

document.body.addEventListener("scroll", ev => {
  (ev.target as HTMLElement).scrollTop = 0;
});

app.use(router);
app.use(store, key);
app.use(TNLifeUIKit);
app.use(infraLayer);
app.use(servicePlugin);
app.directive("observe-visibility", {
  beforeMount: (el, binding, vnode) => {
    // @ts-ignore
    vnode.context = binding.instance;
    // @ts-ignore
    ObserveVisibility.bind(el, binding, vnode);
  },
  // @ts-ignore
  update: ObserveVisibility.update,
  // @ts-ignore
  unmounted: ObserveVisibility.unbind
});
app.mount("#app");
