<template>
  <div class="image-previewer">
    <div
      class="image-previewer__fancybox-loader-background-before"
      :class="{
        'image-previewer__fancybox-loader-background-before_show':
          isShowBackground
      }"
    ></div>
    <div
      class="image-previewer__fancybox-loader-background"
      :class="{
        'image-previewer__fancybox-loader-background_show': isShowBackground,
        'image-previewer__fancybox-loader-background_loading': isAttachmentsLoad
      }"
    >
      <TNIcon
        v-if="isAttachmentsLoad"
        class="image-previewer__fancybox-load-icon"
        name="load"
      />
    </div>
    <div
      :class="{
        'image-previewer__fancybox-loader-background-after_show':
          isShowBackground
      }"
      class="image-previewer__fancybox-loader-background-after"
    ></div>
    <div
      ref="fancyboxContainer"
      class="image-previewer__fancybox-container"
    ></div>
    <transition name="fade-100ms">
      <div
        class="image-previewer__button-panel"
        :class="{
          'image-previewer__button-panel_show': isShowBackground
        }"
      >
        <transition name="fade-100ms">
          <TNButton
            v-if="shareable && !isAttachmentsLoad"
            class="image-previewer__share-button"
            :loading="isFileDownload"
            icon="share"
            size="md"
            @click="share"
          >
            Переслать
          </TNButton>
        </transition>
        <transition name="fade-100ms">
          <TNButton
            v-if="!isAttachmentsLoad"
            class="image-previewer__download-button"
            icon="download"
            size="md"
            @click="download"
          >
            Скачать
          </TNButton>
        </transition>
        <TNButton
          class="image-previewer__close-button"
          size="lg"
          rounded
          icon="close"
          white
          @click="closeWindow"
        />
      </div>
    </transition>
    <div
      v-if="isShowBackground && pdfMode"
      class="image-previewer__bottom-panel"
    >
      <span>{{ page }} / {{ pageCount }}</span>
    </div>
    <div
      v-if="pdfSource && pdfMode"
      ref="pdfContainer"
      class="image-previewer__pdf-view-container"
    >
      <button
        v-if="page > 1"
        class="image-previewer__navigation-button image-previewer__navigation-button_pdf image-previewer__navigation-button_previous"
        @click="previousPage"
      ></button>
      <button
        v-if="page < pageCount"
        class="image-previewer__navigation-button image-previewer__navigation-button_pdf image-previewer__navigation-button_next"
        @click="nextPage"
      ></button>
      <VuePdfEmbed
        ref="pdfComponent"
        class="image-previewer__pdf-embed"
        :class="{ 'image-previewer__pdf-embed_load': isAttachmentsLoad }"
        :source="pdfSource"
        :page="page"
        disable-text-layer
        disable-annotation-layer
        @rendered="handleDocumentRender"
      />
    </div>
  </div>
</template>

<script lang="ts" src="./image-previewer.ts" />

<style lang="css" src="./image-previewer.css" />
