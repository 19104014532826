import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch
} from "vue";
import { useStore } from "vuex";

import { nullUuid } from "@/common/constants";
import { useInfrastructure } from "@/composables/infrastructure";
import { key } from "@/store";

import type { IconNames } from "@life_uikit/uikit/icons/icon-names";

export default defineComponent({
  name: "Avatar",
  props: {
    id: { type: String, default: nullUuid },
    link: String,
    name: { type: String, default: "" },
    size: { type: String as PropType<"s" | "m" | "l">, default: "m" },
    isFavorite: Boolean,
    isChat: Boolean,
    isAttachmentImage: Boolean,
    isAttachmentFile: Boolean,
    fullName: Boolean,
    application: Boolean,
    meeting: Boolean
  },
  setup(props, { slots }) {
    const $infra = useInfrastructure();
    const $store = useStore(key);

    const isInitializing = ref<boolean>(true);
    const emptyLink = ref<boolean>(false);

    onMounted(() => {
      loadImage();
    });

    const url = computed<string>(() => {
      const image = $store.state.fileList.find(
        f =>
          (props.id !== nullUuid && f.uuid === props.id) ||
          f.uuid === props.link
      );

      return (
        image?.base ||
        (!emptyLink.value && image?.url) ||
        (!emptyLink.value && props.link) ||
        ""
      );
    });
    const isSlot = computed<boolean>(() => !!slots.default);
    const shortName = computed<string>(() => {
      if (props.isAttachmentFile || props.isAttachmentImage) {
        return "";
      }
      if (!emptyLink.value && props.isFavorite) {
        return "";
      }
      const emptyName =
        props.application || props.isAttachmentFile || props.isAttachmentImage
          ? ""
          : "#";
      const name = (props.name || "")
        .replaceAll(/[^a-zA-Zа-яА-Я ]/g, "")
        .split(" ")
        .map(string => string[0] || "")
        .join("")
        .toUpperCase()
        .slice(0, 2)
        .trim();

      return name ? name : emptyName;
    });
    const image = computed<string | undefined>(() => {
      if (
        props.isFavorite ||
        !url.value ||
        emptyLink.value ||
        isInitializing.value
      )
        return undefined;

      return url.value;
    });
    const icon = computed<IconNames | undefined>(() => {
      if (props.meeting) {
        return "meets-colored";
      } else if (props.isFavorite) {
        return "favorite-colored";
      } else if (props.application) {
        return "applications-light";
      } else if (props.isAttachmentFile) {
        return "document";
      }

      return undefined;
    });
    const text = computed<string>(() => {
      if (props.meeting) {
        return "";
      }

      return props.fullName ? props.name : shortName.value;
    });

    watch(
      () => props.id,
      () => {
        loadImage();
      }
    );
    watch(
      () => props.link,
      () => {
        loadImage();
      }
    );

    const loadImage = () => {
      if (!url.value.match(/^data:.*;base64,/)) {
        if (props.link) {
          $store.commit("addFile", {
            uuid: props.link,
            name: props.name,
            base: props.link
          });
          isInitializing.value = false;
        } else if (props.id && props.id !== nullUuid) {
          if (!$store.state.fileList.some(image => image.uuid === props.id)) {
            $infra.file
              .Link(props.id, props.isAttachmentImage || props.isAttachmentFile)
              .then(url => {
                fetch(url)
                  .then(response => {
                    if (response.status === 200) {
                      $store.commit("addFile", {
                        uuid: props.id,
                        name: props.name,
                        url
                      });
                    } else {
                      emptyLink.value = true;
                    }
                  })
                  .catch(() => {
                    emptyLink.value = true;
                  });
              })
              .catch(() => {})
              .finally(() => {
                isInitializing.value = false;
              });
          } else {
            isInitializing.value = false;
          }
        } else {
          isInitializing.value = false;
        }
      } else {
        isInitializing.value = false;
      }
    };

    return {
      url,
      shortName,
      image,
      emptyLink,
      isSlot,
      icon,
      text
    };
  }
});
