import { UAParser } from "ua-parser-js";
import { computed, defineComponent, onMounted, PropType, ref } from "vue";

export default defineComponent({
  name: "UpdateOverlay",
  props: {
    hard: { type: Boolean, default: false },
    downloadProgress: { type: Number as PropType<number>, default: 0 },
    downloading: { type: Boolean, default: false },
    error: { type: Boolean, default: false }
  },
  emits: ["download", "later", "retry"],
  setup: props => {
    const isMacos = ref<boolean>(false);

    onMounted(() => {
      isMacos.value =
        new UAParser(navigator.userAgent).getOS().name === "Mac OS";
    });

    const title = computed<string>(() =>
      props.hard ? "Обновите приложение" : "Мы улучшили приложение"
    );

    const description = computed<string>(() =>
      props.hard
        ? "Мы улучшили приложение: добавили новые возможности и<br>доработали старые. Без обновления они не заработают."
        : "Добавили новые возможности и доработали старые."
    );

    const downloadTitle = computed<string>(
      () => "Обновить версию для " + (isMacos.value ? "macOS" : "Windows")
    );

    const strokeDasharray = computed<string>(
      () => `${props.downloadProgress * 478} 8000`
    );

    const downloadProgressText = computed<string>(
      () => Math.ceil(props.downloadProgress * 100) + "%"
    );

    return {
      title,
      isMacos,
      description,
      downloadTitle,
      strokeDasharray,
      downloadProgressText
    };
  }
});
