import { ITNDropdownMenu } from "@life_uikit/uikit/interfaces";
import {
  EmployeeResponse,
  SearchResponse
} from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/search_service_pb";
import { computed, defineComponent, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

import { LSNames, nullUuid } from "@/common/constants";
import Avatar from "@/components/avatar/avatar";
import DropdownMenu from "@/components/dropdown-menu/dropdown-menu";
import { globalSearchAdapter } from "@/components/header/header-adapters";
import {
  GroupView,
  ICustomCellDataItem,
  ResultType
} from "@/components/header/i-header";
import { useError } from "@/composables/error";
import { useInfrastructure } from "@/composables/infrastructure";
import { key } from "@/store";
import { IUser } from "@/store/i-store";

export default defineComponent({
  name: "HeaderComponent",
  components: {
    Avatar,
    DropdownMenu
  },
  emits: ["open-report-form"],
  setup: (_, context) => {
    const $store = useStore(key);
    const $router = useRouter();
    const $route = useRoute();
    const $infra = useInfrastructure();
    const $error = useError();

    const searchString = ref<string>("");
    const isHelpMenuVisible = ref<boolean>(false);
    const helpMenuItems: ITNDropdownMenu[] = [
      {
        title: "Оставить обратную связь",
        subtitle:
          "Расскажите об ошибке или предложите идею по развитию TN Life",
        icon: "edit-2",
        id: "feedback"
      },
      {
        title: "Пройти обучение по TN Life",
        subtitle: "Узнайте, как работает главная страница TN Life",
        icon: "hint",
        id: "reset-tutorials"
      }
    ];
    const searchResult = ref<ICustomCellDataItem[] | null>(null);
    const isSearchLoading = ref<boolean>(false);
    const groupView = ref<Record<GroupView, boolean>>({
      "Мои контакты": false,
      "Телефонная книга": false,
      Каналы: false,
      "Групповые чаты": false,
      "Сообщения в публичных каналах": false,
      Сервисы: false
    });
    const showSearchResult = ref<boolean>(false);
    const avatarElement = ref<typeof Avatar | null>(null);

    let timeoutId: NodeJS.Timeout | null = null;

    const user = computed<IUser | null>(() => $store.state.user);
    const resultList = computed<(ICustomCellDataItem | string)[] | null>(() => {
      if (!searchResult.value) {
        return null;
      }

      const list: (ICustomCellDataItem | string)[] = [];

      const contacts = (searchResult.value || []).filter(
        sr => sr.type === ResultType.Contact
      );
      const employees = (searchResult.value || []).filter(
        sr => sr.type === ResultType.Employee
      );
      const channels = (searchResult.value || []).filter(
        sr => sr.type === ResultType.Channel
      );
      const groups = (searchResult.value || []).filter(
        sr => sr.type === ResultType.Group
      );
      const message = (searchResult.value || []).filter(
        sr => sr.type === ResultType.Message
      );
      const services = (searchResult.value || []).filter(
        sr => sr.type === ResultType.Service
      );

      let groupName: GroupView = "Мои контакты";

      if (contacts.length) {
        list.push(groupName);
        list.splice(
          1,
          0,
          ...contacts.filter((_, i) =>
            groupView.value[groupName] ? i < 3 : true
          )
        );
      }
      if (employees.length) {
        groupName = "Телефонная книга";
        list.push(groupName);
        list.splice(
          list.length,
          0,
          ...employees.filter((_, i) =>
            groupView.value[groupName] ? i < 3 : true
          )
        );
      }
      if (groups.length) {
        groupName = "Групповые чаты";
        list.push(groupName);
        list.splice(
          list.length,
          0,
          ...groups.filter((_, i) =>
            groupView.value[groupName] ? i < 3 : true
          )
        );
      }
      if (channels.length) {
        groupName = "Каналы";
        list.push(groupName);
        list.splice(
          list.length,
          0,
          ...channels.filter((_, i) =>
            groupView.value[groupName] ? i < 3 : true
          )
        );
      }
      if (services.length) {
        groupName = "Сервисы";
        list.push(groupName);
        list.splice(
          list.length,
          0,
          ...services.filter((_, i) =>
            groupView.value[groupName] ? i < 3 : true
          )
        );
      }
      if (message.length) {
        groupName = "Сообщения в публичных каналах";
        list.push(groupName);
        list.splice(
          list.length,
          0,
          ...message.filter((_, i) =>
            groupView.value[groupName] ? i < 3 : true
          )
        );
      }

      return list;
    });
    const isMobile = computed<boolean>(() => $store.state.isMobile);

    watch(
      () => $route.name,
      () => {
        closeSearch();
      }
    );

    const closeHelpMenu = () => {
      isHelpMenuVisible.value = false;
    };
    const openHelpMenu = () => {
      isHelpMenuVisible.value = true;
      closeSearch();
    };
    const resetTutorials = () => {
      localStorage.removeItem(LSNames.MainTutorialRead);
      localStorage.removeItem(LSNames.ServiceTutorialRead);
    };
    const helpSelectHandler = (helpValue: string) => {
      if (helpValue === "feedback") {
        context.emit("open-report-form");
      } else if (helpValue === "reset-tutorials") {
        resetTutorials();
        $store.commit("triggerTutorials");
      }
      closeHelpMenu();
    };
    const queryUpdateHandler = (instant: boolean = false) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
      if (instant && searchString.value.trim().length >= 3) {
        globalSearch();
      } else {
        timeoutId = setTimeout(() => {
          if (searchString.value.trim().length >= 3) {
            globalSearch();
          } else {
            searchResult.value = null;
          }
        }, 500);
      }
    };
    const globalSearch = async () => {
      isSearchLoading.value = true;
      const pendingQuery = searchString.value.trim().replaceAll(/\s+/g, " ");
      let globalSearchResponse: SearchResponse.AsObject | null = null;
      let personSearchResponse: EmployeeResponse.AsObject | null = null;

      try {
        globalSearchResponse = await $infra.search.GlobalSearch(pendingQuery);
      } catch {}
      try {
        personSearchResponse = await $infra.search.EmployeeSearch(pendingQuery);
      } catch {}

      if (pendingQuery === searchString.value.trim().replaceAll(/\s+/g, " ")) {
        searchResult.value = await globalSearchAdapter(
          pendingQuery,
          $store.state.streamStore.streamList,
          globalSearchResponse,
          $store.state.serviceList || [],
          personSearchResponse
        );
        if (
          searchResult.value.filter(sr => sr.type === ResultType.Contact)
            .length > 3
        ) {
          groupView.value["Мои контакты"] = true;
        }
        if (
          searchResult.value.filter(sr => sr.type === ResultType.Channel)
            .length > 3
        ) {
          groupView.value["Каналы"] = true;
        }
        if (
          searchResult.value.filter(sr => sr.type === ResultType.Group).length >
          3
        ) {
          groupView.value["Групповые чаты"] = true;
        }
        if (
          searchResult.value.filter(sr => sr.type === ResultType.Employee)
            .length > 3
        ) {
          groupView.value["Телефонная книга"] = true;
        }
        if (
          searchResult.value.filter(sr => sr.type === ResultType.Message)
            .length > 3
        ) {
          groupView.value["Сообщения в публичных каналах"] = true;
        }
        if (
          searchResult.value.filter(sr => sr.type === ResultType.Service)
            .length > 3
        ) {
          groupView.value["Сервисы"] = true;
        }
        isSearchLoading.value = false;
      }
    };
    const closeSearch = () => {
      searchString.value = "";
      showSearchResult.value = false;
      setTimeout(() => {
        searchResult.value = null;
      }, 300);
    };
    const searchItemClick = async (id: string) => {
      const item = (resultList.value || []).find(
        r => typeof r !== "string" && r.id === id
      );

      if (item && typeof item !== "string") {
        if (
          [ResultType.Channel, ResultType.Group, ResultType.Message].includes(
            item.type
          ) &&
          item.id
        ) {
          const id = item.type === ResultType.Message ? item.streamId : item.id;

          if ($store.state.streamStore.streamList.some(s => s.id === id)) {
            await $router.push({
              name: "chat-page",
              params: {
                id
              },
              query: {
                messageId: ResultType.Message ? item.messageId : undefined
              }
            });
          } else {
            try {
              const streamResponse = await $infra.stream.GetStream({
                streamId: id
              });

              await $infra.stream.Subscribe(streamResponse.id);
              setTimeout(() => {
                $router.push({
                  name: "chat-page",
                  params: {
                    id
                  },
                  query: {
                    messageId: ResultType.Message ? item.messageId : undefined
                  }
                });
              }, 300);
            } catch (e: any) {
              if (e && e.message === "PermissionDenied") {
                await $router.push({
                  name: "chat-page",
                  params: {
                    id
                  }
                });

                return;
              }
              $error({
                message: "Невозможно открыть чат",
                details: JSON.stringify(e)
              });
            }
          }
        } else if (item.type === ResultType.Contact && item.id) {
          await $router.push({
            name: "user-page",
            params: {
              id: item.id
            }
          });
        } else if (item.type === ResultType.Employee) {
          if (item.id && item.id !== nullUuid) {
            await $router.push({
              name: "user-page",
              params: {
                id: item.id
              }
            });
          }
        } else if (item.type === ResultType.Service) {
          await $router.push({
            name: "app",
            params: {
              id: item.id
            }
          });
        }
      }
    };
    const toggleGroupView = (groupName: string) => {
      if (typeof groupView.value[groupName] === "boolean") {
        groupView.value[groupName] = !groupView.value[groupName];
      }
    };
    const openCurrentUser = () => {
      if (user.value) {
        $router.push({
          name: "profile"
        });
      }
    };
    const focusSearchHandler = () => {
      showSearchResult.value = true;
    };
    const blurSearchHandler = () => {
      if (!searchString.value) {
        showSearchResult.value = false;
      }
    };
    const groupTitleClickHandler = (groupName: string) => {
      groupView.value[groupName] = false;
    };

    return {
      user,
      searchString,
      isHelpMenuVisible,
      helpMenuItems,
      searchResult,
      isSearchLoading,
      resultList,
      groupView,
      isMobile,
      showSearchResult,
      avatarElement,
      ResultType,
      groupTitleClickHandler,
      focusSearchHandler,
      blurSearchHandler,
      openCurrentUser,
      queryUpdateHandler,
      closeHelpMenu,
      openHelpMenu,
      closeSearch,
      searchItemClick,
      helpSelectHandler,
      toggleGroupView
    };
  }
});
