import { LSNames } from "@/common/constants";

export async function notificationsMiddleware(): Promise<void> {
  return new Promise(resolve => {
    const store = require("@/store").default;

    if (localStorage.getItem(LSNames.AT)) {
      store.dispatch("notificationsStore/init");
    }
    resolve();
  });
}
