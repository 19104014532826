import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import { key } from "@/store";

export const useLayout = () => {
  const $route = useRoute();
  const $store = useStore(key);

  const isMobile = computed<boolean>(() => $store.state.isMobile);

  const isSimpleLayout = computed(
    () =>
      [
        "auth",
        "logout",
        "redirect",
        "onboarding",
        "404",
        "meet",
        "error"
      ].includes($route.name as string) ||
      (isMobile.value &&
        [
          "app",
          "chat-page",
          "chat-user-page",
          "create-chat",
          "chat-info",
          "settings",
          "profile-page",
          "user-page"
        ].includes($route.name as string))
  );
  const isNoHeader = computed(() =>
    [
      "auth",
      "app",
      "chat-list",
      "chat",
      "chat-page",
      "create-chat",
      "user-page",
      "chat-user-page",
      "chat-info",
      "contacts",
      "profile",
      "meets",
      "profile-page",
      "settings",
      "404"
    ].includes($route.name as string)
  );

  return {
    isSimpleLayout,
    isNoHeader
  };
};
