import { LSNames } from "@/common/constants";
import Infrastructure from "@/infrastructure";
import { Electron } from "@/infrastructure/electron";
import { MainService } from "@/service/index";

const Infra = new Infrastructure();
const service = new MainService();

export class ExternalAPI {
  init() {
    Electron().ipcRenderer.on("auth-request", this.onAuthRequest);
    Electron().ipcRenderer.on("setting-request", this.onSettingRequest);
  }

  private async onAuthRequest(event: any, id: string): Promise<void> {
    const responseKey = `auth-response-${id}`;

    await Infra.auth.RefreshToken();

    const accessToken = localStorage.getItem(LSNames.AT);

    let response: { ok: boolean; code: number; data: string };

    if (accessToken) {
      response = {
        ok: true,
        code: 200,
        data: accessToken
      };
      event.sender.send(responseKey, response);
    } else {
      response = {
        ok: false,
        code: 401,
        data: "isn't logged in"
      };
      event.sender.send(responseKey, response);
    }
    service.groupLog(
      [
        `%c${new Date().toLocaleString()}: %cPlugin%c: %cAuth`,
        "color: initial;font-style: italic",
        "color: #49a1ff;font-weight: bold;",
        "color: initial;font-weight: bold;",
        "color: #e11b11;font-weight: bold;"
      ],
      {
        Response: response
      }
    );
  }

  private async onSettingRequest(event: any, id: string): Promise<void> {
    const responseKey = `setting-response-${id}`;

    const environment = service.getEnvironment();
    const store = require("@/store").default;

    let response: {
      ok: boolean;
      code: number;
      lifeApiUrl?: string;
      meetBaseUrl?: string;
      data?: string;
      isAuthorized?: boolean;
    };

    if (environment) {
      response = {
        ok: true,
        code: 200,
        lifeApiUrl: environment.baseApiEndpoint,
        meetBaseUrl:
          store.state.settingsStore.meetLandingUrl || environment.meetUrl,
        isAuthorized: await Infra.auth.IsLoggedIn()
      };
      event.sender.send(responseKey, response);
    } else {
      response = {
        ok: false,
        code: 500,
        data: "can't get settings"
      };
      event.sender.send(responseKey, response);
    }
    service.groupLog(
      [
        `%c${new Date().toLocaleString()}: %cPlugin%c: %cSettings`,
        "color: initial;font-style: italic",
        "color: #49a1ff;font-weight: bold;",
        "color: initial;font-weight: bold;",
        "color: #e11b11;font-weight: bold;"
      ],
      {
        Response: response
      }
    );
  }
}
