import { Stream } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/stream_pb";
import { ActionTree } from "vuex";

import Infrastructure from "@/infrastructure";
import { IState } from "@/store/i-store";
import { INotificationsStore } from "@/store/modules/notifications/i-notifications";
import { notificationSettingAdapter } from "@/store/modules/notifications/notifications-adapter";

import StreamType = Stream.Type;

const Infra = new Infrastructure();

export default {
  init: async ({ state }) => {
    const notificationsResponse = await Infra.notifications.GetGlobalSettings();

    if (notificationsResponse.channel) {
      state.channels = notificationSettingAdapter(
        notificationsResponse.channel
      );
    }
    if (notificationsResponse.direct) {
      state.direct = notificationSettingAdapter(notificationsResponse.direct);
    }
    if (notificationsResponse.group) {
      state.groups = notificationSettingAdapter(notificationsResponse.group);
    }
  },
  setNotificationSetting: (
    { state },
    payload: {
      streamId: string;
      mute: boolean;
      type: StreamType;
    }
  ) => {
    let index = -1;
    let streamType = "";

    if (payload.type === StreamType.CHANNEL) {
      index = state.channels.uuidList.findIndex(id => id === payload.streamId);
      streamType = "channels";
    } else if (payload.type === StreamType.DIRECT) {
      index = state.direct.uuidList.findIndex(id => id === payload.streamId);
      streamType = "direct";
    } else if (payload.type === StreamType.GROUP) {
      index = state.groups.uuidList.findIndex(id => id === payload.streamId);
      streamType = "groups";
    }

    Infra.notifications.SetStreamSettings(payload).then(() => {
      if (index === -1 && streamType) {
        state[streamType].uuidList.push(payload.streamId);
      } else if (streamType) {
        state[streamType].uuidList.splice(index, 1);
      }
    });
  },
  clearAllData: ({ state }) => {
    state.channels = {
      uuidList: [],
      muted: false
    };
    state.channels = {
      uuidList: [],
      muted: false
    };
    state.direct = {
      uuidList: [],
      muted: false
    };
  }
} as ActionTree<INotificationsStore, IState>;
