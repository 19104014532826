<template>
  <div class="dropdown-menu">
    <TNDropdown
      class="dropdown-menu__dropdown"
      style="position: absolute"
      :is-visible="showMenu"
      :options="menuItems"
      :style="style"
      :shrink="false"
      @click:outside="closeOptionsMenu"
      @select="selectMenu"
    />
  </div>
</template>

<script lang="ts" src="./dropdown-menu.ts" />

<style lang="css" src="./dropdown-menu.css" />
