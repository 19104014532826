<template>
  <TNBottomSheet
    :is-open="isOpen"
    :header="header"
    custom-class="offline-popup"
  >
  </TNBottomSheet>
</template>

<script lang="ts" src="./offline-popup.ts"></script>

<style lang="css" src="./offline-popup.css"></style>
