import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TNButton = _resolveComponent("TNButton")!
  const _component_TNBottomSheet = _resolveComponent("TNBottomSheet")!

  return (_openBlock(), _createBlock(_component_TNBottomSheet, {
    "is-open": _ctx.visible,
    header: _ctx.popupHeader,
    "lock-swipe": _ctx.lockSwipe,
    "is-mobile-mini-app": "",
    "full-height": _ctx.fullHeight,
    "custom-class": _ctx.customClassList,
    onHide: _ctx.closePopup
  }, {
    header: _withCtx(() => [
      _renderSlot(_ctx.$slots, "header")
    ]),
    default: _withCtx(() => [
      (_ctx.popup.body)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            innerHTML: _ctx.popup.body
          }, null, 8 /* PROPS */, _hoisted_1))
        : _createCommentVNode("v-if", true),
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.resizable)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "popup__resize-handle",
            onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.starResizing && _ctx.starResizing(...args)))
          }, null, 32 /* NEED_HYDRATION */))
        : _createCommentVNode("v-if", true)
    ]),
    footer: _withCtx(() => [
      _renderSlot(_ctx.$slots, "footer"),
      (_ctx.popup.buttons && _ctx.popup.buttons.length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["popup__button-container", 
          'popup__button-container_' + (_ctx.popup.buttonsLayout || 'horizontal')
        ])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.popup.buttons, (button) => {
              return (_openBlock(), _createBlock(_component_TNButton, {
                ref_for: true,
                ref: "buttons",
                key: button.text,
                class: "popup__button",
                icon: button.icon,
                outline: button.type === 'outline',
                white: button.type === 'link',
                secondary: button.type === 'secondary',
                disabled: _ctx.isButtonsLoading || button.disabled,
                loading: button.loading,
                size: "lg",
                style: _normalizeStyle({
            flexBasis:
              _ctx.popup.buttonsLayout === 'horizontal' || !_ctx.popup.buttonsLayout
                ? 'calc(' + 100 / _ctx.popup.buttons?.length + '% - 16px)'
                : ''
          }),
                onClick: ($event: any) => (_ctx.buttonClickHandler(button))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(button.text), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["icon", "outline", "white", "secondary", "disabled", "loading", "style", "onClick"]))
            }), 128 /* KEYED_FRAGMENT */))
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["is-open", "header", "lock-swipe", "full-height", "custom-class", "onHide"]))
}