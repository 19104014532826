import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import { finalMiddleware } from "@/middleware/common/final-middleware";

export function onboardingMiddleware(
  _: RouteLocationNormalized,
  __: RouteLocationNormalized,
  next: NavigationGuardNext
): void {
  finalMiddleware();
  next();
}
