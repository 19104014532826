import Dexie, { type EntityTable } from "dexie";

import { IDBRecentEmoji } from "@/composables/recent-emoji";

const mainDB = new Dexie("TN Life") as Dexie & {
  recentEmojis: EntityTable<IDBRecentEmoji, "u">;
};

// migrating https://dexie.org/docs/Dexie/Dexie.version()
mainDB.version(1).stores({
  recentEmojis: "++u, n, count"
});

export default mainDB;

export const clearDB = async () => {
  await Dexie.delete("TN Life");
};
