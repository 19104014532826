import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "meet-call" }
const _hoisted_2 = { class: "meet-call__text-container" }
const _hoisted_3 = {
  key: 0,
  class: "meet-call__title"
}
const _hoisted_4 = { class: "meet-call__subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_TNButton = _resolveComponent("TNButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.authorList.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["meet-call__icon-container", {
        'meet-call__icon-container_two': _ctx.call.participantCount === 2,
        'meet-call__icon-container_three': _ctx.call.participantCount >= 3
      }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.authorList, (author) => {
            return (_openBlock(), _createBlock(_component_Avatar, {
              key: author.avatarUrl,
              class: "meet-call__avatar",
              name: author.name,
              link: author.avatarUrl
            }, null, 8 /* PROPS */, ["name", "link"]))
          }), 128 /* KEYED_FRAGMENT */)),
          (_ctx.call.participantCount > 3)
            ? (_openBlock(), _createBlock(_component_Avatar, {
                key: 0,
                class: "meet-call__avatar",
                name: _ctx.authorListCounter,
                "full-name": ""
              }, null, 8 /* PROPS */, ["name"]))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.call.subject)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.call.subject), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.subtitle), 1 /* TEXT */)
    ]),
    _createVNode(_component_TNButton, {
      class: "meet-call__button meet-call__button_reject",
      icon: "close",
      size: "md",
      rounded: "",
      disabled: _ctx.loading,
      onClick: _ctx.reject
    }, null, 8 /* PROPS */, ["disabled", "onClick"]),
    (!_ctx.waitingAnswer)
      ? (_openBlock(), _createBlock(_component_TNButton, {
          key: 1,
          class: "meet-call__button meet-call__button_confirm",
          icon: "check",
          size: "md",
          rounded: "",
          disabled: _ctx.loading,
          onClick: _ctx.answer
        }, null, 8 /* PROPS */, ["disabled", "onClick"]))
      : _createCommentVNode("v-if", true)
  ]))
}