import { defineComponent } from "vue";

export default defineComponent({
  name: "OfflinePopup",
  props: {
    isOpen: Boolean
  },
  setup: () => {
    const header = {
      title: "Нет сети",
      description: "Для продолжения работы включите интернет на устройстве"
    };

    return { header };
  }
});
