import {
  SearchResponse,
  EmployeeResponse
} from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/search_service_pb";
import { Stream as OuterStream } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/stream_pb";

import { nullUuid } from "@/common/constants";
import Infrastructure from "@/infrastructure";
import { StreamService } from "@/service";
import { Stream } from "@/store/modules/stream/i-stream";
import { IApp } from "@/views/services/i-services";

import { ICustomCellDataItem, ResultType } from "./i-header";

const streamService = new StreamService();
const Infra = new Infrastructure();

export async function globalSearchAdapter(
  query: string,
  streamList: Stream[],
  globalResult: SearchResponse.AsObject | null,
  serviceList: IApp[],
  employeeResult?: EmployeeResponse.AsObject | null
): Promise<ICustomCellDataItem[]> {
  const list: ICustomCellDataItem[] = [];

  if (globalResult) {
    for (let i = 0; i < globalResult.streamsList.length; i++) {
      const stream = globalResult.streamsList[i];

      if (
        [OuterStream.Type.CHANNEL, OuterStream.Type.GROUP].includes(stream.type)
      ) {
        let logoUrl = "";

        if (
          stream.logo?.id &&
          stream.logo.id !== nullUuid &&
          !stream.logo.photolargeurl
        ) {
          try {
            logoUrl = await Infra.file.Link(stream.logo.id);
          } catch {}
        }
        list.push({
          id: stream.id,
          type:
            stream.type === OuterStream.Type.CHANNEL
              ? ResultType.Channel
              : ResultType.Group,
          title: stream.title,
          subtitle: stream.description,
          rightIcons: ["right-m"],
          rightIconsAlignment: "center",
          userPicture: {
            image: stream.logo?.photolargeurl || logoUrl,
            text: stream.title
              .split(" ")
              .map((t, i) => (i < 2 ? t[0] || "" : ""))
              .join("")
          },
          verified: stream.systemtype === OuterStream.SystemType.SUPER_CHANNEL
        });
      }
    }

    for (let i = 0; i < globalResult.messagesList.length; i++) {
      const messageItem = globalResult.messagesList[i];

      for (let i = 0; i < messageItem.messagesList.length; i++) {
        let subtitle = "",
          title = "",
          messageId = "",
          avatarUrl = "",
          verified = false;
        const message = streamService.messageParser(
          messageItem.messagesList[i]
        );

        if (message) {
          subtitle = message.content;
          messageId = message.id;
        }
        const stream = streamList.find(s => s.id === messageItem.streamid);

        if (stream) {
          title = stream.title;
          avatarUrl = stream.iconUrl || "";
          verified = stream.isSuperChannel;
        } else {
          let externalStream: OuterStream.AsObject | null = null;

          try {
            externalStream = await Infra.stream.GetStream({
              streamId: messageItem.streamid
            });
          } catch (e) {}
          if (externalStream) {
            title = externalStream.title;
            avatarUrl = externalStream.logo?.photolargeurl || "";
            verified =
              externalStream.systemtype ===
              OuterStream.SystemType.SUPER_CHANNEL;
          }
        }
        if (title) {
          list.push({
            id: messageItem.streamid + message.id,
            type: ResultType.Message,
            title,
            subtitle,
            rightIcons: ["right-m"],
            rightIconsAlignment: "center",
            userPicture: {
              image: avatarUrl,
              text: title
                .split(" ")
                .map((t, i) => (i < 2 ? t[0] || "" : ""))
                .join("")
            },
            streamId: messageItem.streamid,
            messageId,
            verified
          });
        }
      }
    }
  }

  if (employeeResult) {
    employeeResult.contactsList.forEach(contact => {
      list.push({
        id: String(contact.portalcode),
        type: ResultType.Contact,
        title: `${contact.surname} ${contact.name} ${contact.patronym}`,
        subtitle: contact.position,
        rightIcons: ["right-m"],
        rightIconsAlignment: "center",
        userPicture: {
          image: contact.avatar?.photolargeurl,
          text: (contact.name[0] || "") + (contact.surname[0] || "")
        }
      });
    });

    employeeResult.employeesList.forEach(employee => {
      list.push({
        id: String(employee.portalcode),
        type: ResultType.Employee,
        title: `${employee.surname} ${employee.name} ${employee.patronym}`,
        subtitle: employee.position,
        rightIcons: ["right-m"],
        rightIconsAlignment: "center",
        userPicture: {
          image: employee.avatar?.photolargeurl,
          text: (employee.name[0] || "") + (employee.surname[0] || "")
        }
      });
    });
  }

  for (let i = 0; i < serviceList.length; i++) {
    const service = serviceList[i];

    if (
      query
        .toLowerCase()
        .split(" ")
        .every(
          queryWord =>
            service.title
              .toLowerCase()
              .split(" ")
              .some(serviceTitleWord =>
                serviceTitleWord.startsWith(queryWord)
              ) ||
            service.description
              ?.toLowerCase()
              .split(" ")
              .some(serviceDescriptionWord =>
                serviceDescriptionWord.startsWith(queryWord)
              )
        ) &&
      !service.siteAsService
    ) {
      let iconUrl = "";

      try {
        iconUrl = await Infra.file.Link(service.logoId);
      } catch {}
      list.push({
        id: service.uuid,
        type: ResultType.Service,
        title: service.title,
        subtitle: service.description || "",
        rightIcons: ["right-m"],
        rightIconsAlignment: "center",
        userPicture: {
          icon: "applications-light",
          image: iconUrl
        }
      });
    }
  }

  return list;
}
