import { ActionTree } from "vuex";

import { LSNames } from "@/common/constants";
import { IState } from "@/store/i-store";
import { ISettingsStore } from "@/store/modules/settings/i-settings";

export default {
  init({ commit, dispatch }) {
    const developerMode = localStorage.getItem(LSNames.Dev);

    if (developerMode === "1") {
      commit("enableDeveloperMode");
    }
    commit("getCustomServiceList");
    const maxBackgroundApps = Number(localStorage.getItem(LSNames.MaxBGApps));

    if (
      maxBackgroundApps &&
      !isNaN(maxBackgroundApps) &&
      maxBackgroundApps > 1
    ) {
      dispatch("setMaxBackgroundApps", maxBackgroundApps);
    }
    commit(
      "setRingtoneEnabled",
      (localStorage.getItem(LSNames.RingtoneEnabled) || "1") !== "0"
    );
    commit(
      "setShowSidebarTooltip",
      (localStorage.getItem(LSNames.ShowTooltip) || "0") !== "0"
    );
    commit(
      "setCallDeviceSettings",
      JSON.parse(localStorage.getItem(LSNames.CallSettings) || "{}")
    );
    dispatch(
      "setAfkTimeout",
      JSON.parse(localStorage.getItem(LSNames.AfkTimeout) || "60")
    );
    dispatch(
      "setRecordExpirationTime",
      JSON.parse(localStorage.getItem(LSNames.RecordExpirationTime) || "90")
    );
  },
  setMaxBackgroundApps({ state, rootState, commit, getters }, count: number) {
    state.maxBackgroundApps = count;
    while (rootState.runningApps.length > getters.getMaxBackgroundApps) {
      const oldestApp = [...rootState.runningApps].sort((a1, a2) =>
        a1.opened > a2.opened ? 1 : a1.opened < a2.opened ? -1 : 0
      )[0];
      const oldestAppIndex = rootState.runningApps.findIndex(
        a => oldestApp && a.id === oldestApp.id
      );

      if (oldestAppIndex !== -1) {
        commit("closeApp", rootState.runningApps[oldestAppIndex].id, {
          root: true
        });
      }
    }
    if (state.developerMode) {
      localStorage.setItem(
        LSNames.MaxBGApps,
        state.maxBackgroundApps.toString()
      );
    }
  },
  clearAllData({ state }) {
    state.customServiceList = [];
    state.enableMarkAllStreamsAsRead = false;
    state.userManualLink = "";
  },
  disableDeveloperMode({ state, dispatch }) {
    localStorage.removeItem(LSNames.Dev);
    state.developerMode = false;
    dispatch("setMaxBackgroundApps", state.maxBackgroundApps);
  },
  setAfkTimeout({ state, dispatch }, count: number) {
    state.afkTimeout = count;
    dispatch("updateData", null, { root: true });
    localStorage.setItem(LSNames.AfkTimeout, count.toString());
  },
  setRecordExpirationTime({ state }, time: number) {
    state.recordExpirationTime = time;
    localStorage.setItem(LSNames.RecordExpirationTime, time.toString());
  }
} as ActionTree<ISettingsStore, IState>;
