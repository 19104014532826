import { computed } from "vue";
import { useStore } from "vuex";

import { key } from "@/store";
import { IMutationToast } from "@/store/i-store";

export const useToast = () => {
  const $store = useStore(key);

  const toastList = computed(() => $store.state.toasts);

  return (data: IMutationToast | string) => {
    if (
      !toastList.value.length ||
      !toastList.value.some(
        t =>
          (typeof data === "string" && t.text === data) ||
          (typeof data !== "string" && t.text === data.text)
      )
    ) {
      $store.commit("toast", data);
    }
  };
};
