import { MainService } from "@/service";

const service = new MainService();

export async function finalMiddleware(): Promise<void> {
  const envName = service.getEnvironment()?.name;

  if (envName !== "prod" && !service.isBrowser() && !STAGE_MODE) {
    document.title = `TN Life v${VUE_APP_VERSION} [${envName}]`;
  }
}
