export interface IServiceFile {
  name: string;
  uploadedFile?: {
    mime_type: string;
    name: string;
    size: number;
    url: string;
  };
  error: boolean;
  warn: boolean;
  file: File;
  base: string;
}

/* eslint-disable no-unused-vars */
export enum SplashState {
  Visible,
  Loaded,
  Hidden
}
