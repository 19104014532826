import { App } from "vue";

import Infrastructure from "./index";

// noinspection JSUnusedGlobalSymbols
export default {
  install: function (Vue: App) {
    const infra = new Infrastructure();

    Vue.config.globalProperties.infra = infra;
    Vue.provide("infra", infra);
  }
};
