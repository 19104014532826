import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import { LSNames } from "@/common/constants";
import { authMiddleware } from "@/middleware/common/auth-middleware";
import { finalMiddleware } from "@/middleware/common/final-middleware";
import { notificationsMiddleware } from "@/middleware/common/notifications-middleware";
import { streamMiddleware } from "@/middleware/common/stream-middleware";
import store from "@/store";

export async function homepageMiddleware(
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  if (to.name !== "redirect") {
    await authMiddleware();
    await loadServiceList();
    await notificationsMiddleware();
    await streamMiddleware();
    await finalMiddleware();
  }
  next();
}

export async function loadServiceList(): Promise<void> {
  if (!store.state.serviceList && localStorage.getItem(LSNames.AT)) {
    store.state.serviceList = [];
    await store.dispatch("initAppList");
  }
}
