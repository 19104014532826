import { GlobalNotifySetting } from "@superapp/life-proto/pkg-ts/tnlife/chat/v2/notification_service_pb";

import { INotification } from "@/store/modules/notifications/i-notifications";

export const notificationSettingAdapter = (
  setting: GlobalNotifySetting.AsObject
): INotification => {
  return {
    uuidList: setting.exceptionsList.map(exception => exception.streamid),
    muted: setting.notification?.mute || false
  };
};
