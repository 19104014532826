import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import { authMiddleware } from "@/middleware/common/auth-middleware";
import { streamMiddleware } from "@/middleware/common/stream-middleware";

export async function meetMiddleware(
  _: RouteLocationNormalized,
  __: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  await authMiddleware();
  await streamMiddleware();
  next();
}
