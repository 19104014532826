import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watch
} from "vue";
import { useStore } from "vuex";

import ringtone from "@/assets/audio/call.mp3";
import waiting from "@/assets/audio/waiting-ringtone.wav";
import Avatar from "@/components/avatar/avatar";
import { key } from "@/store";
import { IIncomingCall } from "@/store/i-store";

export default defineComponent({
  name: "MeetCall",
  components: {
    Avatar
  },
  props: {
    call: { type: Object as PropType<IIncomingCall>, required: true },
    loading: Boolean,
    waitingAnswer: Boolean,
    rejected: Boolean
  },
  emits: ["reject", "answer", "close"],
  setup: (props, { emit }) => {
    const store = useStore(key);

    const isTimeoutRejected = ref<boolean>(false);

    let audio: HTMLAudioElement | null = null;

    onMounted(() => {
      if (store.state.settingsStore.isRingtoneEnabled && !isBrowser.value) {
        audio = new Audio(props.waitingAnswer ? waiting : ringtone);
        audio.play();
        audio.addEventListener("ended", () => {
          if (store.state.settingsStore.isRingtoneEnabled && audio) {
            audio.play();
          }
        });
      }

      setTimeout(() => {
        isTimeoutRejected.value = true;
        setTimeout(() => {
          emit("close");
        }, 300);
      }, store.state.settingsStore.callTimeout);
    });

    watch(
      () => props.rejected,
      to => {
        if (to) {
          setTimeout(() => {
            emit("close");
          }, 300);
        }
      }
    );

    onBeforeUnmount(() => {
      if (audio) {
        audio.pause();
        audio = null;
      }
    });

    const authorList = computed<{ name: string; avatarUrl: string }[]>(() => {
      const authors = props.call.participants;

      if (authors) {
        return authors.filter((_, i) =>
          props.call.participantCount > 3 ? i < 2 : i <= 2
        );
      }

      return [];
    });
    const authorListCounter = computed<string>(() => {
      if (props.call.participantCount > 3) {
        return "+" + (props.call.participantCount - 2);
      }

      return "";
    });
    const subtitle = computed<string>(() => {
      if (props.waitingAnswer) {
        if (isRejected.value) {
          return "Звонок отклонён";
        }

        return "Идёт вызов...";
      }
      if (props.call.group && props.call.participants.length) {
        return "Начинает звонок " + props.call.participants[0].name;
      }

      return "Входящий звонок...";
    });
    const isRejected = computed<boolean>(
      () => props.rejected || isTimeoutRejected.value
    );
    const isBrowser = computed(() => store.state.isBrowser);

    const reject = () => {
      emit("reject");
    };

    const answer = () => {
      emit("answer");
    };

    return {
      authorList,
      authorListCounter,
      subtitle,
      answer,
      reject
    };
  }
});
