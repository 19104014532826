<template>
  <div class="update-overlay">
    <template v-if="error">
      <div class="update-overlay__image update-overlay__image_error"></div>
      <h2 class="update-overlay__title">
        Что-то пошло не так
      </h2>
      <p class="update-overlay__description">
        Проверьте доступ к интернету и повторите попытку.
      </p>
      <TNButton
        class="update-overlay__retry-button"
        size="lg"
        @click="$emit('retry')"
      >
        Повторить
      </TNButton>
      <TNButton
        v-if="!hard"
        class="update-overlay__proceed-button"
        size="lg"
        secondary
        @click="$emit('later')"
      >
        Пропустить
      </TNButton>
    </template>
    <template v-else-if="downloading">
      <svg
        class="update-overlay__load-progress"
        width="158px"
        height="158px"
        viewBox="0 0 158 158"
        fill="none"
      >
        <circle
          r="76"
          cx="79"
          cy="79"
          stroke-width="6"
          stroke="#F3F5F7"
        />
        <circle
          transform="rotate(-90 79 79)"
          r="76"
          cx="79"
          cy="79"
          stroke-width="6"
          :stroke-dasharray="strokeDasharray"
          stroke-linecap="round"
          stroke="#E11B11"
        />
        <text
          x="79"
          y="87"
          fill="#1e2228"
          font-weight="600"
          text-anchor="middle"
          font-size="24px"
        >
          {{ downloadProgressText }}
        </text>
      </svg>
      <p class="update-overlay__description">
        Скачиваем обновление. <br />
        После загрузки приложение закроется, <br />и запустится установка.
      </p>
    </template>
    <template v-else>
      <div class="update-overlay__image"></div>
      <h2 class="update-overlay__title">
        {{ title }}
      </h2>
      <p class="update-overlay__description" v-html="description"></p>
      <ul class="update-overlay__button-list">
        <li
          class="update-overlay__button-item update-overlay__button-item_download"
          @click="$emit('download')"
        >
          <TNIcon
            v-if="isMacos"
            class="update-overlay__button-left-icon"
            name="apple-filled"
          />
          <TNIcon
            v-else
            class="update-overlay__button-left-icon"
            name="windows-filled"
          />
          <p class="update-overlay__button-title">
            {{ downloadTitle }}
          </p>
          <p class="update-overlay__button-description">
            После загрузки приложение закроется,<br />и запустится установка
          </p>
          <TNIcon class="update-overlay__button-right-icon" name="download" />
        </li>
        <li
          v-if="!hard"
          class="update-overlay__button-item"
          @click="$emit('later')"
        >
          <TNIcon
            class="update-overlay__button-left-icon"
            name="clock-filled"
          />
          <p class="update-overlay__button-title">
            Обновить позже
          </p>
          <p class="update-overlay__button-description">
            Напомним обновить приложение <br />через несколько дней
          </p>
          <TNIcon class="update-overlay__button-right-icon" name="goto" />
        </li>
      </ul>
    </template>
  </div>
</template>

<script src="./update-overlay.ts" lang="ts"></script>

<style src="./update-overlay.css" lang="css"></style>
