import { WebSocket } from "@/infrastructure/websocket/websocket";
import { IUser } from "@/store/i-store";

import actions from "./actions";
import { Stream } from "./i-stream";
import mutations from "./mutations";

export default {
  namespaced: true,
  state: () => ({
    webSocket: null as WebSocket | null,
    streamList: [] as Stream[],
    errorHandler: null as Function | null,
    disconnectHandler: null as Function | null,
    isLoading: false as boolean,
    isFullLoading: false as boolean,
    isWebSocketConnecting: false as boolean,
    isWebSocketOnline: false as boolean,
    users: [] as IUser[]
  }),
  actions,
  mutations
};
