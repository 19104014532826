import { useStore } from "vuex";

import { key } from "@/store";
import { IMutationPopup } from "@/store/i-store";

export const usePopup = () => {
  const $store = useStore(key);

  return {
    $popup: (payload: IMutationPopup) => {
      $store.commit("showPopup", payload);
    },
    $closePopup: (index: number) => {
      $store.commit("closePopup", index);
    }
  };
};
