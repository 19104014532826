import { INotification } from "@/store/modules/notifications/i-notifications";

import actions from "./actions";

export default {
  namespaced: true,
  state: {
    channels: {
      uuidList: [],
      muted: false
    } as INotification,
    groups: {
      uuidList: [],
      muted: false
    } as INotification,
    direct: {
      uuidList: [],
      muted: false
    } as INotification
  },
  actions
};
