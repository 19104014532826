<template>
  <div class="meet-call">
    <div
      v-if="authorList.length"
      class="meet-call__icon-container"
      :class="{
        'meet-call__icon-container_two': call.participantCount === 2,
        'meet-call__icon-container_three': call.participantCount >= 3
      }"
    >
      <Avatar
        v-for="author in authorList"
        :key="author.avatarUrl"
        class="meet-call__avatar"
        :name="author.name"
        :link="author.avatarUrl"
      />
      <template v-if="call.participantCount > 3">
        <Avatar class="meet-call__avatar" :name="authorListCounter" full-name />
      </template>
    </div>
    <div class="meet-call__text-container">
      <p v-if="call.subject" class="meet-call__title">
        {{ call.subject }}
      </p>
      <p class="meet-call__subtitle">
        {{ subtitle }}
      </p>
    </div>
    <TNButton
      class="meet-call__button meet-call__button_reject"
      icon="close"
      size="md"
      rounded
      :disabled="loading"
      @click="reject"
    />
    <TNButton
      v-if="!waitingAnswer"
      class="meet-call__button meet-call__button_confirm"
      icon="check"
      size="md"
      rounded
      :disabled="loading"
      @click="answer"
    />
  </div>
</template>

<script src="./meet-call.ts" lang="ts"></script>

<style src="./meet-call.css" lang="css"></style>
