import { IEnvironment } from "@/service";

export const imageExtensionArray = [".bmp", ".gif", ".jpeg", ".jpg", ".png"];

export const supportedBridgeMethods = [
  "LifeMiniAppInit",
  "LifeMiniAppAuthCode",
  "LifeMiniAppCopyText",
  "LifeMiniAppGetAppLink",
  "LifeMiniAppGetClientVersion",
  "LifeMiniAppOpenStream",
  "LifeMiniAppOpenDirectStream",
  "LifeMiniAppSupports",
  "LifeMiniAppDownloadFile",
  "LifeMiniAppOpenFile",
  "LifeMiniAppSaveToGallery",
  "LifeMiniAppTakePicture",
  "LifeMiniAppPickFile",
  "LifeMiniAppPickFiles",
  "LifeMiniAppHaptic",
  "LifeMiniAppShowSplash",
  "LifeMiniAppHideSplash",
  "LifeMiniAppNoAccess",
  // "LifeMiniAppShare",
  // "LifeMiniAppShareFile",
  "LifeMiniAppOpenUrl",
  "LifeMiniAppSetTheme"
];

export const grpcErrors = [
  "",
  "Сетевое подключение было отменено",
  "Неизвестная ошибка сети",
  "Поле заполнено некорректно",
  "Превышено ожидание ответа от сервера",
  "Ничего не найдено",
  "Запись уже существует",
  "Доступ запрещен",
  "Недостаточно ресурсов для обработки запроса",
  "Сервер не может обработать запрос",
  "Подключение было прервано сервером",
  "Превышен допустимый диапазон",
  "Операция не поддерживается сервером",
  "Внутренняя ошибка сервера",
  "Сервер временно недоступен",
  "Данные повреждены или потеряны",
  "Требуется авторизация"
];

export const environments: IEnvironment[] = [
  {
    name: "tj-dev",
    centrifugeEndpoint: "wss://ws2-tn-life-dev.tages.dev/connection/websocket",
    baseApiEndpoint: "https://api-tn-life-dev.tages.dev",
    meetUrl: "https://tn-meet-dev.tages.dev/",
    webUrl: "https://web-tn-life-dev.tages.dev",
    oneSignalAppId: "caacd748-5141-4ae4-ad38-2a43a65874b7"
  },
  {
    name: "tj-test",
    centrifugeEndpoint: "wss://ws2-tn-life-test.tages.dev/connection/websocket",
    baseApiEndpoint: "https://api-tn-life-test.tages.dev",
    meetUrl: "https://tn-meet-test.tages.dev/",
    webUrl: "https://web-tn-life-test.tages.dev",
    oneSignalAppId: "caacd748-5141-4ae4-ad38-2a43a65874b7"
  },
  {
    name: "tj-test-upd",
    centrifugeEndpoint:
      "wss://ws2-tn-life-test-upd.tages.dev/connection/websocket",
    baseApiEndpoint: "https://api-tn-life-test-upd.tages.dev",
    meetUrl: "https://tn-meet-test-upd.tages.dev/",
    webUrl: "https://web-tn-life-test-upd.tages.dev",
    oneSignalAppId: "caacd748-5141-4ae4-ad38-2a43a65874b7"
  },
  {
    name: "tj-autotest",
    centrifugeEndpoint:
      "wss://ws2-tn-life-autotest.tages.dev/connection/websocket",
    baseApiEndpoint: "https://api-tn-life-autotest.tages.dev",
    meetUrl: "https://tn-meet-autotest.tages.dev/",
    webUrl: "https://web-tn-life-autotest.tages.dev",
    oneSignalAppId: "caacd748-5141-4ae4-ad38-2a43a65874b7"
  },
  {
    name: "tj-accept",
    centrifugeEndpoint:
      "wss://ws2.tn-life-accept.tjdev.ru/connection/websocket",
    baseApiEndpoint: "https://api.tn-life-accept.tjdev.ru",
    meetUrl: "https://tn-meet-accept.tjdev.ru/",
    webUrl: "https://web.tn-life-accept.tjdev.ru",
    oneSignalAppId: "caacd748-5141-4ae4-ad38-2a43a65874b7"
  },
  {
    name: "tj-stage",
    centrifugeEndpoint: "wss://ws2.tn-life-stage.tjdev.ru/connection/websocket",
    baseApiEndpoint: "https://api.tn-life-stage.tjdev.ru",
    meetUrl: "https://tn-meet-stage.tjdev.ru/",
    webUrl: "https://web.tn-life-stage.tjdev.ru",
    oneSignalAppId: "caacd748-5141-4ae4-ad38-2a43a65874b7"
  },
  {
    name: "prod",
    centrifugeEndpoint: "wss://ws2.tnlife.ru/connection/websocket",
    baseApiEndpoint: "https://api.tnlife.ru",
    meetUrl: "https://meet.tnlife.ru/",
    webUrl: "https://web.tnlife.ru/meet",
    oneSignalAppId: "35ccac56-51d7-4fa8-82a8-aaecd429a460"
  },
  {
    name: "stage",
    centrifugeEndpoint: "wss://ws2.tnlife.tndev.ru/connection/websocket",
    baseApiEndpoint: "https://api.tnlife.tndev.ru",
    meetUrl: "https://meet.tnlife.tndev.ru/",
    webUrl: "https://web.tnlife.tndev.ru",
    oneSignalAppId: "fe57c899-0035-4204-85c8-42d1cb0e402a"
  }
];

export const nullUuid = "00000000-0000-0000-0000-000000000000";

export const threeDays = 259200000;

export const validServices: {
  name: string;
  titles: string[];
  uuids: string[];
}[] = [
  {
    name: "booking",
    titles: [
      "Бронирование переговорок",
      "Бронь переговорок",
      "Бронирование переговорных",
      "Бронь переговорных"
    ],
    uuids: []
  },
  {
    name: "phoneBook",
    titles: ["Контакты", "Телефонная книга"],
    uuids: [
      "0fc990e3-3b40-4003-a819-5168afe18a26", // dev
      "5083e76a-1767-4abd-993f-d1a37b5f1eb7", // test
      "0fc990e3-3b40-4003-a819-5168afe18a26", // accept
      "e68f58ea-a8b6-4f78-a05c-d2d00c34179c" // prod
    ]
  },
  {
    name: "finApp",
    titles: ["Финансовый помощник"],
    uuids: [
      "1edce480-1a41-47f4-9e84-f7950ded06df", // dev
      "f990c12a-5dad-43bc-b1a7-6106f7e6c4b5", // test
      "2126735c-7a5c-4661-816f-7368f6b3661a", // accept
      "1f0416b4-965b-4b90-427a-4ff8a62f2699" // prod
    ]
  },
  {
    name: "activeTN",
    titles: ["Активный сотрудник"],
    uuids: [
      "de95f466-0eba-4b33-a40c-cb8e322337c3", // dev
      "b173f602-67fc-4608-87e6-84a1fb07a422", // test
      "cad6fcbd-6172-45dd-a60d-b1574e4750e3", // accept
      "fbe88961-8d22-4349-ad57-6125f11e3cff" // prod
    ]
  },
  {
    name: "knowledgeBase",
    titles: ["База знаний"],
    uuids: [
      "3039c87f-b8bb-4a1b-af03-4ed370226c8f", // dev
      "2f53ee2c-dd0b-486d-ae81-0ea0cf689f11", // test
      "f9fa3576-e7a2-2342-7846-6424faa05a80", // accept
      "063d42ee-dffa-b250-5d1e-155630ef8b3b" // prod
    ]
  },
  {
    name: "projectOffice",
    titles: ["Проектный офис"],
    uuids: [
      "2a1e41fa-3a40-47e4-9256-f2f67e4cc7cc", // dev
      "db1fefb2-7118-4225-b0c9-47802c5553d5", // test
      "8d873ea6-e373-486b-808c-aa0732a18b7e", // accept
      "5bb67b9b-fb36-e4d6-b5e2-e53db61af132" // prod
    ]
  },
  {
    name: "directives",
    titles: ["Распоряжения"],
    uuids: []
  }
];

export const sixWeeks = 518400000;

export const fancyboxLocaleRu = {
  CLOSE: "Закрыть",
  NEXT: "Дальше",
  PREV: "Назад",
  MODAL: "Вы можете закрыть данное окно, нажав клавишу ESC",
  ERROR: "Что-то пошло не так. Пожалуйста, повторите попытку позже",
  IMAGE_ERROR: "Изображение не найдено",
  ELEMENT_NOT_FOUND: "HTML элемент не найден",
  AJAX_NOT_FOUND: "Ошибка загрузки AJAX : Не найдено",
  AJAX_FORBIDDEN: "Ошибка загрузки AJAX : Запрещено",
  IFRAME_ERROR: "Ошибка загрузки страницы",
  TOGGLE_ZOOM: "Переключить уровень масштаба",
  TOGGLE_THUMBS: "Переключить эскиз",
  TOGGLE_SLIDESHOW: "Переключить презентацию",
  TOGGLE_FULLSCREEN: "Переключить режим полного экрана",
  DOWNLOAD: "Скачать"
};

export const toolbarButtons = [
  "camera", // камера
  // "chat", чат
  // "closedcaptions", субтитры
  "desktop", // демнострацния экрана/вкладки/окна
  // "download", предложение скачать версию приложения для моб устройства
  // "embedmeeting", встроить эту встречу
  // "etherpad", интеграция с Etherpad
  // "feedback", меню отзыва
  "filmstrip", // скрытие-разворот сайдбара-списка участников
  "fullscreen", // переключение полноэкранного режима
  // "hangup", кнопка сброса звонка
  // "help", меню помощи?
  // "highlight", ???
  // "invite", меню приглашения других пользователей по ссылке
  // "linktosalesforce", ссылка на SalesForce при интеграции
  // "livestreaming", совместный просмотр онлайн-трансляций
  "microphone", // микрофон
  "noisesuppression", // шумоподавление
  // "participants-pane", панель участников
  // "profile", страница пользователя
  // "raisehand", поднять руку и реакции
  // "recording", запись звонка
  // "security", настройки безопасности
  "select-background", // выбор фона видео
  "settings", // меню настроек
  // "shareaudio", передача звука из экрана/вкладки/окна
  // "sharedvideo", поделиться видео по ссылке для совместного просмотра
  // "stats", Статистика времени спикеров, кто сколько времени говорит
  // "tileview", отображение участников в режиме плиток
  // "toggle-camera", переключить на фронтальную/тыльную камеру
  // "videoquality", выбор качества видео
  // "whiteboard", доска для рисования
  "shortcuts" // комбинация клавиш
];

export const linkRegex =
  /^(((blob|data):.*)|((https?:\/\/(www\.)?)?[-a-zA-Zа-яА-Я0-9]{1,256}\.[a-zA-Zа-яА-Я0-9]{1,6}([-a-zA-Zа-яА-Я0-9()@:%_+.~#?&/=]*)))$/;
export const messageLinkRegexString =
  "(?<![\"./>])(?<= \\b)https?:\\/\\/(www\\.)?[-a-zA-Zа-яА-ЯёË0-9.]{1,256}\\.[a-zA-Zа-яА-ЯёË0-9]{2,6}([/#][-a-zA-Zа-яА-ЯёË0-9()@:%_+.~#?&/=]*)?(?=(\\b|\\/|#) )(?![\"<])";

export const mailLinkRegex = /^mailto:\S+@\S+\.\S+$/;
export const mailRegex =
  /^[a-zA-Z0-9][a-zA-Z0-9._-]+@[a-zA-Z0-9][a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+$/;

export const linkRegexLocalhost =
  /^https?:\/\/(www\.)?[-a-zA-Zа-яА-Я0-9]{1,256}((\.[a-zA-Zа-яА-Я0-9]{1,6})+)?([0-9]{2,5})?(:\d+)?([-a-zA-Zа-яА-Я0-9()@:%_+.~#?&/=]{1,256})?$/;

export const uuidRegex = /^[0-9a-fA-F]{8}(-[0-9a-fA-F]{4}){3}-[0-9a-fA-F]{12}$/;

export const uuidFragmentRegex =
  /[0-9a-fA-F]{8}(-[0-9a-fA-F]{4}){3}-[0-9a-fA-F]{12}/;
export const appIdFragmentRegex =
  /([0-9a-fA-F]{8}(-[0-9a-fA-F]{4}){3}-[0-9a-fA-F]{12}|([a-z0-9_.-]{3,50})$)/;

export const hexColorRegex = /^[0-9a-f]{6}([0-9a-f]{2})?$/i;

export const jwtRegex = /^[\w-]*\.[\w-]*\.[\w-]*$/;

export const weekDays = [
  "понедельник",
  "вторник",
  "среда",
  "четверг",
  "пятница",
  "суббота",
  "воскресенье"
];

/* eslint-disable no-unused-vars */
export enum LSNames {
  MainTutorialRead = "tn-life__main-tutorial-read",
  ServiceTutorialRead = "tn-life__service-tutorial-read",
  AT = "tn-life__access-token",
  RT = "tn-life__refresh-token",
  Dev = "tn-life__developer-mode",
  DelayNewVersion = "tn-life__delay-new-version",
  SeenOnboarding = "tn-life__seen-onboarding",
  Environment = "tn-life__environment",
  EnvironmentsList = "tn-life__environments-list",
  DynLinkOrigins = "tn-life__dynlink-origins",
  MaxBGApps = "tn-life__max-background-apps",
  RingtoneEnabled = "tn-life__ringtone-enabled",
  ShowTooltip = "tn-life__show-tooltip",
  CallSettings = "tn-life__call-settings",
  CustomServiceList = "tn-life__custom-service-list",
  OpenAtLogin = "tn-life__open-at-login",
  AfkTimeout = "tn-life__afk-timeout",
  RecordExpirationTime = "tn-life__record-expiration-time",
  DarkTheme = "tn-life__dark-theme",
  BlurMode = "tn-life__blur-mode",
  TokenWorkerBusy = "tn-life__token-worker-busy"
}

export enum SSNames {
  TWID = "tn-life__token-worker-id"
}
