<template>
  <div class="popup-list">
    <Popup
      v-for="popupItem in popupList"
      :key="popupItem.index"
      :popup="popupItem"
      :visible="!popupItem.hidden"
      @close="closePopup(popupItem)"
    />
  </div>
</template>

<script lang="ts" src="./popup-list.ts" />
