<template>
  <!-- :style="style" -->
  <main
    class="layout"
    :class="{
      'layout_no-focus': !isAppFocused && !isBrowser,
      layout_app: !isBrowser,
      layout_pwa: isPWA
    }"
  >
    <!-- Кастомный тайтл бар -->
    <!-- <div class="layout__drag-bar"></div> -->
    <transition name="fade">
      <div v-if="devMode" class="layout__dev-badge">
        Dev
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="devMode && !isOnline"
        class="layout__dev-badge layout__dev-badge_offline"
      >
        Offline
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="devMode && !isCentrifugeOnline"
        class="layout__dev-badge layout__dev-badge_centrifuge-offline"
      >
        Centrifuge Offline
      </div>
    </transition>
    <div
      class="layout__main-container"
      :class="{ 'layout__main-container_lock-scroll': isOnboarding }"
    >
      <HeaderComponent
        v-if="!isSimpleLayout && !isNoHeader"
        class="layout__header"
        @open-report-form="openReportForm"
      />
      <main
        class="layout__body"
        :class="{ 'layout__body_sub-page': isSubpage }"
      >
        <router-view></router-view>
      </main>
    </div>
    <Service
      v-for="app in runningApps"
      :id="app.id"
      :key="app.id"
      :query="$route.query"
      :visible="isShowApp(app)"
      class="layout__service-page"
      :class="{ 'layout__service-page_hide': !isShowApp(app) }"
      @clearQuery="clearQuery"
      @updateQuery="updateQuery(app.id, $event)"
    />
    <Sidebar v-if="!isSimpleLayout" class="layout__sidebar" />
    <ReportForm
      :form="feedbackForm"
      @send-form="sendForm"
      @close="closeReportForm"
    />
    <div class="layout__meet-call-container">
      <transition-group name="layout__meet-call">
        <div
          v-for="incomingCallWindow in incomingCallWindows"
          :key="incomingCallWindow.room"
          class="layout__meet-call-item"
        >
          <MeetCall
            class="layout__meet-call"
            :call="incomingCallWindow"
            :loading="!callsLoading.length && callsLoading.includes(incomingCallWindow.room)"
            @reject="rejectCall(incomingCallWindow.room)"
            @answer="answerCall(incomingCallWindow.room)"
            @close="closeIncomingCall(incomingCallWindow.room)"
          />
        </div>
      </transition-group>
      <transition name="layout__meet-call">
        <div v-if="waitingCallWindow" class="layout__meet-call-item">
          <MeetCall
            class="layout__meet-call"
            :call="waitingCallWindow"
            waiting-answer
            :rejected="isWaitingCallRejected"
            :loading="!callsLoading.length && callsLoading.includes(waitingCallWindow.room)"
            @reject="cancelWaitingCall"
            @close="closeWaitingCall"
          />
        </div>
      </transition>
    </div>
    <PopupList :popup-list="popupList" @close="popupCloseHandler" />
    <TNToaster
      class="layout__toast-list"
      :class="{ 'layout__toast-list_no-padding': toastNoPadding && !isMobile }"
      :toast-list="toastList"
      :bottom="isMobile"
      @closeToast="toastCloseHandler"
    />
    <UpdateOverlay
      v-if="isUpdateRequired"
      class="layout__update-overlay"
      :downloading="isUpdateDownloading"
      :download-progress="updateDownloadProgress"
      :error="isUpdateError"
      :hard="isUpdateHard"
      @later="updateLaterHandler"
      @download="updateDownloadHandler"
      @retry="updateRetryHandler"
    />
    <OfflinePopup :is-open="!isOnline" />
  </main>
</template>

<script src="./layout.ts" lang="ts"></script>

<style src="./layout.css" lang="css"></style>
