import { LSNames } from "@/common/constants";

export async function streamMiddleware(): Promise<void> {
  return new Promise(resolve => {
    const store = require("@/store").default;

    if (
      localStorage.getItem(LSNames.AT) &&
      !store.state.streamStore.webSocket?.isSocketConnected()
    ) {
      store.dispatch("streamStore/init");
    }
    resolve();
  });
}
