import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "header__user-container" }
const _hoisted_3 = {
  key: 0,
  class: "header__user-greeting-text"
}
const _hoisted_4 = { class: "header__navigation-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_TNSearch = _resolveComponent("TNSearch")!
  const _component_TNButton = _resolveComponent("TNButton")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.user)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_Avatar, {
              id: _ctx.user.avatar?.id,
              ref: "avatarElement",
              link: _ctx.user.avatar?.url,
              name: _ctx.user.renderName,
              class: "header__user-image",
              "is-chat": "",
              onClick: _ctx.openCurrentUser
            }, null, 8 /* PROPS */, ["id", "link", "name", "onClick"]),
            (_ctx.user.firstName)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, " Здравствуйте, " + _toDisplayString(_ctx.user.firstName) + "! ", 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_TNSearch, {
        modelValue: _ctx.searchString,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchString) = $event)),
        "search-hint": "Введите 3 символа, чтобы начать поиск",
        size: _ctx.isMobile ? 's' : 'm',
        class: "header__search-input",
        loading: _ctx.isSearchLoading,
        placeholder: "Поиск по TN Life",
        "show-result": _ctx.showSearchResult,
        result: _ctx.resultList,
        "result-max-height": 0,
        "nothing-found-title": "Ничего не найдено",
        "nothing-found-description": "Попробуйте изменить поисковый запрос",
        "nothing-found-badge": "",
        cell: "",
        select: "",
        "group-title-button": "Показать больше",
        "show-group-titles": _ctx.groupView,
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.queryUpdateHandler(false))),
        onFocus: _ctx.focusSearchHandler,
        onBlur: _ctx.blurSearchHandler,
        onSelect: _ctx.searchItemClick,
        "onClick:groupTitle": _ctx.groupTitleClickHandler
      }, null, 8 /* PROPS */, ["modelValue", "size", "loading", "show-result", "result", "show-group-titles", "onFocus", "onBlur", "onSelect", "onClick:groupTitle"]),
      _createElementVNode("div", {
        class: "header__search-background",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.closeSearch && _ctx.closeSearch(...args)))
      }),
      _createVNode(_component_TNButton, {
        icon: "help",
        white: "",
        size: "md",
        class: "header__help-button",
        onClick: _ctx.openHelpMenu
      }, null, 8 /* PROPS */, ["onClick"]),
      _createVNode(_component_DropdownMenu, {
        top: "32",
        right: "0",
        "show-menu": _ctx.isHelpMenuVisible,
        "menu-items": _ctx.helpMenuItems,
        "button-class-name": "header__help-button",
        onSelect: _ctx.helpSelectHandler,
        onClose: _ctx.closeHelpMenu
      }, null, 8 /* PROPS */, ["show-menu", "menu-items", "onSelect", "onClose"])
    ])
  ]))
}