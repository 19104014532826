import { computed, ref } from "vue";
import { useStore } from "vuex";

import { IPopup, IPopupButton } from "@/components/popup/i-popup";
import { useInfrastructure } from "@/composables/infrastructure";
import { useService } from "@/composables/service";
import { useToast } from "@/composables/toast";
import { Electron } from "@/infrastructure/electron";
import store, { key } from "@/store";

// eslint-disable-next-line no-unused-vars
export const useConfirmEmail = (serviceCallback?: (close: boolean) => void) => {
  const $store = useStore(key);
  const $infra = useInfrastructure();
  const { $service } = useService();
  const $toast = useToast();

  const isConfirmPopupVisible = ref<boolean>(false);
  const isOpenedProfileWindow = ref<boolean>(false);
  const edit = ref<boolean>(false);

  const confirmPopupText = computed<string>(() => {
    if (isOpenedProfileWindow.value) {
      return `Если вам удалось ${edit.value ? "изменить" : "подтвердить"} электронную почту в цифровом профиле, ${serviceCallback ? "войдите в сервис" : "вернитесь в профиль TN Life"}.`;
    } else {
      return `Перейдите в цифровой профиль и ${edit.value ? "измените" : "подтвердите"} электронную почту. После — вернитесь на эту страницу.`;
    }
  });
  const confirmPopupTitle = computed<string>(() => {
    if (isOpenedProfileWindow.value) {
      return serviceCallback ? "Войдите в сервис" : "Вернитесь в профиль";
    } else {
      return `${edit.value ? "Измените" : "Подтвердите"} электронную почту`;
    }
  });
  const confirmPopupButtons = computed<IPopupButton[]>(() => {
    if (isOpenedProfileWindow.value) {
      return [
        {
          text: serviceCallback ? "Войти" : "Вернуться в профиль TN Life",
          type: "primary",
          callback: () => {
            refreshAndReload();
          }
        }
      ];
    }

    return [
      {
        text: "Перейти в цифровой профиль",
        type: isOpenedProfileWindow.value ? "link" : "primary",
        callback: () => {
          openProfilePage();
        }
      }
    ];
  });
  const confirmPopup = computed<IPopup>(() => ({
    text: confirmPopupText.value,
    title: confirmPopupTitle.value,
    buttonsLayout: "vertical",
    buttons: confirmPopupButtons.value
  }));

  const openEmailConfirmPopup = async (editValue: boolean = false) => {
    edit.value = editValue;
    if (!edit.value && !serviceCallback) {
      const user = await updateUser();

      if (user.email) return;
    }
    isConfirmPopupVisible.value = true;
  };
  const refreshAndReload = async (close: boolean = false) => {
    isConfirmPopupVisible.value = false;
    isOpenedProfileWindow.value = false;
    await updateUser();
    if (serviceCallback) {
      serviceCallback(close);
    }
  };
  const updateUser = async () => {
    await $infra.auth.RefreshToken();
    const user = await $infra.user.GetUser();

    store.commit("setUser", user);

    return user;
  };
  const openProfilePage = async () => {
    isOpenedProfileWindow.value = true;
    if ($store.state.settingsStore.profileOrigin) {
      if ($service.isBrowser()) {
        const profileWindow = window.open(
          $store.state.settingsStore.profileOrigin,
          "profilePage",
          "height=620,width=1000"
        );

        profileWindow?.focus();

        setTimeout(() => {
          if (!profileWindow || profileWindow.closed) {
            $toast({
              text: "Браузер запретил отрытие окна встречи",
              type: "warn",
              duration: 10000
            });
          }
        }, 100);

        return false;
      } else {
        Electron().ipcRenderer.send(
          "open-window",
          $store.state.settingsStore.profileOrigin
        );
      }
    }
  };

  return {
    isConfirmPopupVisible,
    confirmPopup,
    closeEmailConfirmPopup: () => {
      refreshAndReload(true);
    },
    openEmailConfirmPopup
  };
};
