// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar {
  --secondary-color: var(--content-accent-enabled);
}

.tn-user-picture.avatar_application {
  background-color: transparent;
}

.message:not(.message_by-me) .tn-user-picture.avatar_attachment {
  background-color: var(--message-buble);
}

.message_by-me .tn-user-picture.avatar_attachment {
  background-color: var(--dark-message-buble);
}

.tn-user-picture.avatar_attachment-file {
  background-color: #60acff;
  color: var(--content-primary-b-enabled);
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/avatar/avatar.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;AAClD;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,yBAAyB;EACzB,uCAAuC;EACvC,YAAY;AACd","sourcesContent":[".avatar {\n  --secondary-color: var(--content-accent-enabled);\n}\n\n.tn-user-picture.avatar_application {\n  background-color: transparent;\n}\n\n.message:not(.message_by-me) .tn-user-picture.avatar_attachment {\n  background-color: var(--message-buble);\n}\n\n.message_by-me .tn-user-picture.avatar_attachment {\n  background-color: var(--dark-message-buble);\n}\n\n.tn-user-picture.avatar_attachment-file {\n  background-color: #60acff;\n  color: var(--content-primary-b-enabled);\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
