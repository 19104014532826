// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tn-bottom-sheet.offline-popup .tn-bottom-sheet__close {
  display: none;
}

.tn-bottom-sheet_desktop.offline-popup .tn-bottom-sheet__header {
  padding: 24px;
}

.tn-bottom-sheet__background:has(.offline-popup) {
  background-color: #1e222866;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/components/offline-popup/offline-popup.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".tn-bottom-sheet.offline-popup .tn-bottom-sheet__close {\n  display: none;\n}\n\n.tn-bottom-sheet_desktop.offline-popup .tn-bottom-sheet__header {\n  padding: 24px;\n}\n\n.tn-bottom-sheet__background:has(.offline-popup) {\n  background-color: #1e222866;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
