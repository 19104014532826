// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/fonts/ProximaNova-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/fonts/ProximaNova-Semibold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/fonts/ProximaNova-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Proxima Nova";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Nova";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  font-weight: 700;
  font-style: normal;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/fonts.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,4CAAmD;EACnD,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,2BAA2B;EAC3B,4CAAoD;EACpD,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,2BAA2B;EAC3B,4CAAgD;EAChD,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["@font-face {\n  font-family: \"Proxima Nova\";\n  src: url(\"~@/assets/fonts/ProximaNova-Regular.ttf\");\n  font-weight: 400;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Proxima Nova\";\n  src: url(\"~@/assets/fonts/ProximaNova-Semibold.ttf\");\n  font-weight: 600;\n  font-style: normal;\n}\n@font-face {\n  font-family: \"Proxima Nova\";\n  src: url(\"~@/assets/fonts/ProximaNova-Bold.ttf\");\n  font-weight: 700;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
