// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup_resizing::after {
  content: "";
  background-color: transparent;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000000;
  cursor: nwse-resize;
}

.popup_error .tn-bottom-sheet__header-row {
  -webkit-user-select: text;
          user-select: text;
}

.popup__button-container {
  display: flex;
  justify-content: flex-end;
  margin: 0 -8px;
}

.popup__button-container_horizontal {
  justify-content: space-between;
}

.popup__button-container_horizontal .popup__button {
  flex-grow: 1;
  flex-shrink: 0;
}

.popup__button-container_vertical {
  flex-flow: column;
}

.popup__button-container_vertical .popup__button:not(:last-child) {
  margin-bottom: 8px;
}

.popup__button {
  margin: 0 8px;
}

.popup__resize-handle {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 24px;
  height: 24px;
  display: block;
  background-color: var(--content-tertiary-enabled);
  cursor: nwse-resize;
}
`, "",{"version":3,"sources":["webpack://./src/components/popup/popup.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,6BAA6B;EAC7B,eAAe;EACf,MAAM;EACN,SAAS;EACT,QAAQ;EACR,OAAO;EACP,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,cAAc;EACd,iDAAiD;EACjD,mBAAmB;AACrB","sourcesContent":[".popup_resizing::after {\n  content: \"\";\n  background-color: transparent;\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  z-index: 1000000;\n  cursor: nwse-resize;\n}\n\n.popup_error .tn-bottom-sheet__header-row {\n  user-select: text;\n}\n\n.popup__button-container {\n  display: flex;\n  justify-content: flex-end;\n  margin: 0 -8px;\n}\n\n.popup__button-container_horizontal {\n  justify-content: space-between;\n}\n\n.popup__button-container_horizontal .popup__button {\n  flex-grow: 1;\n  flex-shrink: 0;\n}\n\n.popup__button-container_vertical {\n  flex-flow: column;\n}\n\n.popup__button-container_vertical .popup__button:not(:last-child) {\n  margin-bottom: 8px;\n}\n\n.popup__button {\n  margin: 0 8px;\n}\n\n.popup__resize-handle {\n  content: \"\";\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  width: 24px;\n  height: 24px;\n  display: block;\n  background-color: var(--content-tertiary-enabled);\n  cursor: nwse-resize;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
