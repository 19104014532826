import { defineComponent, PropType } from "vue";

import { IPopup } from "@/components/popup/i-popup";
import Popup from "@/components/popup/popup";

export default defineComponent({
  name: "PopupList",
  components: {
    Popup
  },
  props: {
    popupList: { type: Array as PropType<IPopup[]>, default: () => [] }
  },
  setup(_, context) {
    const closePopup = (popup: IPopup) => {
      context.emit("close", popup.index);
    };

    const newLineText = (text: string) => {
      return text.replaceAll("\n", "<br />");
    };

    return {
      closePopup,
      newLineText
    };
  }
});
