import { TNTreeProps } from "@life_uikit/uikit/interfaces";
import { computed, defineComponent, PropType, ref, watch } from "vue";
import { useStore } from "vuex";

import { IPopup } from "@/components/popup/i-popup";
import Popup from "@/components/popup/popup";
import { ReportType } from "@/components/report-form/i-report-form";
import { key } from "@/store";

const initTypeList = (): TNTreeProps.Option[] => [
  {
    title: "Предложение",
    id: ReportType.Improvement,
    deep: 0,
    isCheck: false
  },
  {
    title: "Ошибка",
    id: ReportType.Error,
    deep: 0,
    isCheck: false
  },
  {
    title: "Доступ к сервису",
    id: ReportType.Access,
    deep: 0,
    isCheck: false
  }
];

export default defineComponent({
  name: "ReportForm",
  components: {
    Popup
  },
  props: {
    form: {
      type: Boolean as PropType<boolean | { type: string; context?: string }>,
      required: true
    }
  },
  emits: ["sendForm", "close"],
  setup: (props, context) => {
    const $store = useStore(key);

    const typeList = ref<TNTreeProps.Option[]>(initTypeList());
    const selectedType = ref<ReportType[]>([]);
    const reportBody = ref<string>("");
    const isSend = ref<boolean>(false);
    const isFlattened = ref<boolean>(false);

    const isSentFormButtonDisabled = computed(
      () => !(reportBody.value && selectedType.value.length)
    );
    const popup = computed<IPopup>(() => ({
      text: isSend.value
        ? ""
        : "Сообщите об ошибке, запросите доступ к сервису или предложите, как нам сделать приложение лучше",
      title: isSend.value ? "Спасибо!" : "Предложение по улучшению TN Life",
      buttonsLayout: "horizontal",
      buttons: isSend.value
        ? [
            {
              text: "Написать ещё",
              type: "outline",
              callback: () => {
                resetForm();
                initSelectOptions();
              }
            },
            {
              text: "Закрыть",
              callback: closeWindow
            }
          ]
        : [
            {
              text: "Отправить",
              callback: () => {
                submitForm();
              },
              disabled: isSentFormButtonDisabled.value
            }
          ]
    }));
    const successAnnotation = computed<string>(() => {
      if (selectedType.value[0] === ReportType.Improvement) {
        return "Мы рассмотрим вашу идею и улучшим приложение";
      } else if (selectedType.value[0] === ReportType.Access) {
        return "Мы уже передали запрос в службу заботы";
      }

      return "Мы проверим, почему возникла ошибка,<br />и всё поправим";
    });
    const successTitle = computed<string>(() => {
      if (selectedType.value[0] === ReportType.Access) {
        return "Вы запросили доступ";
      }

      return "Спасибо!";
    });
    const isMobile = computed<boolean>(() => $store.state.isMobile);
    const isTypeSelectorDisabled = computed<boolean>(
      () => typeof props.form === "object"
    );

    watch(
      () => props.form,
      to => {
        if (to) {
          initSelectOptions();
        }
      }
    );

    const updateTypeValue = (value: ReportType[]) => {
      selectedType.value = value;
    };
    const submitForm = (event?: Event) => {
      if (event) event.preventDefault();
      context.emit("sendForm", {
        type: selectedType.value[0],
        text: reportBody.value
      });
      isSend.value = true;
      isFlattened.value = isSend.value;
    };
    const closeWindow = () => {
      resetForm();
      context.emit("close");
    };
    const resetForm = () => {
      selectedType.value = [];
      reportBody.value = "";
      isSend.value = false;
    };
    const initSelectOptions = () => {
      typeList.value = initTypeList();
      if (props.form && typeof props.form === "object") {
        if (props.form.type === "colleagues") {
          typeList.value.push({
            title: "Список коллег",
            id: ReportType.Extra,
            deep: 0,
            isCheck: false
          });
          selectedType.value = [ReportType.Extra];
        } else if (props.form.type === "request-access") {
          selectedType.value = [ReportType.Access];
          reportBody.value = props.form.context
            ? "Название сервиса: " + props.form.context + "\n\n"
            : "";
        } else if (props.form.type === "report-error") {
          selectedType.value = [ReportType.Error];
          reportBody.value = props.form.context
            ? "Название сервиса: " + props.form.context + "\n\n"
            : "";
        }
      } else {
        selectedType.value = [];
      }
    };

    return {
      typeList,
      selectedType,
      popup,
      reportBody,
      isSend,
      isFlattened,
      isTypeSelectorDisabled,
      successAnnotation,
      successTitle,
      isMobile,
      updateTypeValue,
      closeWindow
    };
  }
});
