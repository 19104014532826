import { nullUuid } from "@/common/constants";
import { IFancyboxImage } from "@/components/image-previewer/i-image-previewer";
import Infrastructure from "@/infrastructure";
import store from "@/store";
import { IAttachment } from "@/store/modules/stream/i-stream";

const Infra = new Infrastructure();

export const fancyboxPreviewAdapter = async (
  images: IAttachment[],
  isFile: boolean,
  noDownload: boolean
): Promise<IFancyboxImage[]> => {
  const resultList: IFancyboxImage[] = [];

  for (let i = 0; i < images.length; i++) {
    const image = images[i];

    if (noDownload) {
      resultList.push({
        src: image.base || "",
        thumb: image.thumb?.id || "",
        caption: image.uuid,
        name: image.name
      });
    } else {
      const savedImage = store.state.fileList.find(i => i.uuid === image.uuid);

      if (savedImage || image.uuid === nullUuid) {
        if (savedImage && (savedImage.url || savedImage.base)) {
          resultList.push({
            src: savedImage.base || savedImage.url || "",
            thumb: savedImage.base || savedImage.url || "",
            caption: savedImage.uuid,
            name: image.name
          });
        } else if (image.uuid === nullUuid && image.base) {
          resultList.push({
            src: image.base || "",
            thumb: "",
            caption: nullUuid,
            name: image.name
          });
        }
      } else {
        const url = await Infra.file.Link(image.uuid, isFile);

        store.commit("addFile", {
          uuid: image.uuid,
          name: image.name,
          url
        });
        resultList.push({
          src: url,
          thumb: url,
          caption: image.uuid,
          name: image.name
        });
      }
    }
  }

  return resultList;
};
