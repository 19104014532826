import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TNBottomSheet = _resolveComponent("TNBottomSheet")!

  return (_openBlock(), _createBlock(_component_TNBottomSheet, {
    "is-open": _ctx.isOpen,
    header: _ctx.header,
    "custom-class": "offline-popup"
  }, null, 8 /* PROPS */, ["is-open", "header"]))
}