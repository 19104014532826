import { Service } from "@superapp/life-proto/pkg-ts/tnlife/chat/v2/miniapp_pb";

import { IApp, ISite } from "@/views/services/i-services";

export const appListAdapter = (
  appsList: Service.AsObject[],
  siteList: Service.AsObject[]
): IApp[] => {
  const resultList: IApp[] = [];

  appsList.forEach(app => {
    resultList.push({
      description: app.description,
      source: app.source,
      title: app.title,
      uuid: app.id,
      widgetUrl: app.widgeturl || "",
      logoId: app.logo,
      logoMime: "image/png",
      version: app.version,
      priority: app.priority,
      createDate: app.createdat?.seconds || 0
    });
  });

  siteList.forEach(app => {
    if (app.siteasservice) {
      resultList.push({
        description: app.description,
        source: app.source,
        title: app.title,
        uuid: app.id,
        widgetUrl: "",
        logoId: app.logo,
        logoMime: "image/png",
        version: "",
        needsAuth: app.needauth,
        siteAsService: true,
        priority: app.priority,
        createDate: app.createdat?.seconds || 0
      });
    }
  });

  return resultList;
};

export const siteListAdapter = (siteList: Service.AsObject[]): ISite[] => {
  return siteList
    .filter(s => !s.siteasservice)
    .map(site => ({
      uuid: site.id,
      title: site.title,
      url: site.source,
      needsAuth: site.needauth,
      priority: site.priority,
      createDate: site.createdat?.seconds || 0
    }));
};
