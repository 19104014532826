<template>
  <div
    class="navigation-item"
    :class="{
      'navigation-item_button':
        navigation && (navigation.path || navigation.click),
      'navigation-item_active': active,
      'navigation-item_background-run': background,
      'navigation-item_background-active': backgroundActive,
      'navigation-item_notification': notification?.count,
      'navigation-item_system': system,
      'navigation-item_avatar': navigation.image && !navigation.iconName
    }"
  >
    <div v-if="navigation.iconName" class="navigation-item__icon">
      <TNIcon :name="navigation.iconName" />
    </div>
    <div v-else-if="navigation.image || navigation.imageName">
      <Avatar
        class="navigation-item__avatar"
        :link="navigation.image"
        :name="navigation.imageName"
      />
    </div>
    <p class="navigation-item__text" v-html="navigation.name"></p>
    <transition name="scale">
      <div
        v-if="notification && notification.count > 0"
        class="navigation-item__notification-counter"
        :class="{
          'navigation-item__notification-counter_only-muted':
            notification?.muted
        }"
      >
        {{
          notification && notification.count < 10 ? notification.count : "9+"
        }}
      </div>
    </transition>
  </div>
</template>

<script lang="ts" src="./navigation-item.ts" />

<style lang="css" src="./navigation-item.css" />
