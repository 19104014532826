import { LSNames } from "@/common/constants";
import Infrastructure from "@/infrastructure";
import router from "@/router";
import store from "@/store";

const Infra = new Infrastructure();

export async function authMiddleware() {
  if (localStorage.getItem(LSNames.AT)) {
    if (!store.state.user) {
      try {
        const user = await Infra.user.GetUser();

        store.commit("setUser", user);
        void Infra.user.UpdateProfileTimezone();
      } catch {
        void router.push({
          name: "error",
          query: {
            description: "Произошла ошибка при загрузке данных пользователя"
          }
        });
      }
    }
  } else {
    if (
      localStorage.getItem(LSNames.SeenOnboarding) ||
      window.innerWidth < 768
    ) {
      await router.push("/auth");
    } else if (window.VUE_APP_ENABLE_ONBOARDING) {
      await router.push("/onboarding");
    } else {
      await router.push("/auth");
    }
  }
}
