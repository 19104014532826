import { defineComponent, PropType } from "vue";

import Avatar from "@/components/avatar/avatar.vue";
import { INavigation } from "@/components/sidebar/i-sidebar";

export default defineComponent({
  name: "NavigationItem",
  components: { Avatar },
  props: {
    navigation: { type: Object as PropType<INavigation>, required: true },
    notification: {
      type: Object as PropType<{
        count: number;
        muted: boolean;
      }>
    },
    system: Boolean,
    active: Boolean,
    background: Boolean,
    backgroundActive: Boolean
  }
});
