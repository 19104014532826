import { MeetListResponse } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/meet_service_pb";
import { Message } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/message_pb";
import { SubscribedStream } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/stream_pb";
import { CallType } from "@superapp/life-proto/pkg-ts/tnlife/chat/v3/system_notify_pb";
import { jwtDecode } from "jwt-decode";
import { ActionTree } from "vuex";

import { LSNames, nullUuid } from "@/common/constants";
import Infrastructure from "@/infrastructure";
import { WebSocket } from "@/infrastructure/websocket/websocket";
import { MainService, StreamService } from "@/service";
import {
  IColleagues,
  IIncomingCall,
  IState,
  IStore,
  IUser
} from "@/store/i-store";
import {
  Centrifuge,
  IMessage,
  InitMode,
  IStreamStore,
  Stream
} from "@/store/modules/stream/i-stream";
import {
  getUsersFromSubscribedList,
  subscribedStreamsAdapter
} from "@/store/modules/stream/stream-adapters";

const Infra = new Infrastructure();
const service = new MainService();
const streamService = new StreamService();

export default {
  async init({ state, dispatch, rootState }, mode: InitMode = InitMode.Main) {
    if (mode !== InitMode.Background) {
      state.isLoading = true;
      state.isFullLoading = true;
      rootState.contactListLoad = true;
    }

    if (state.webSocket) {
      state.webSocket.disconnect();
    }

    if (mode === InitMode.Reload) {
      rootState.contactList = null;
      state.streamList = [];
      dispatch("loadColleagues", null, { root: true });
    }

    dispatch("initializeCentrifuge");

    try {
      const contacts = await Infra.contacts.GetContactsList();

      rootState.contactList = contacts.map(user =>
        streamService.personAdapter(user)
      );
    } catch {
      rootState.contactList = null;
    }
    rootState.contactListLoad = false;
    const uploadSubscribedList = async (
      subscribedList: SubscribedStream.AsObject[]
    ) => {
      dispatch("addUsers", [...(rootState.contactList || [])]);
      dispatch(
        "addUsers",
        await getUsersFromSubscribedList(subscribedList, state.users)
      );
      subscribedStreamsAdapter(
        subscribedList,
        rootState.user!,
        state.users,
        rootState.contactList || []
      )
        .filter(
          s1 =>
            !state.streamList.length ||
            !state.streamList.some(s2 => s2.id === s1.id) ||
            mode === InitMode.Background
        )
        .forEach(stream => {
          if (mode === InitMode.Background) {
            dispatch("updateStream", stream);
          } else {
            dispatch("appendStream", stream);
          }
        });
      dispatch("updateBadgeCount");
    };

    const subscribedList = await Infra.stream.GetSubscribedList(true);

    await uploadSubscribedList(subscribedList);

    state.isLoading = false;

    const subscribedFullList = await Infra.stream.GetSubscribedList(
      false,
      true
    );

    await uploadSubscribedList(subscribedFullList);

    let meetListResponse: MeetListResponse.AsObject = {
      oncomingmeetList: [],
      activemeetList: []
    };

    try {
      meetListResponse = await Infra.stream.MeetList();
    } catch {}

    state.streamList.forEach(stream => {
      const activeMeet = meetListResponse.activemeetList.find(
        am => am.streamid === stream.id
      );

      if (activeMeet) {
        stream.setCurrentCall({ roomId: activeMeet.roomid });
      }

      const oncomingMeeting = meetListResponse.oncomingmeetList.find(
        om => om.streamid === stream.id
      );

      if (oncomingMeeting?.date) {
        stream.setOncomingMeet({
          meetId: oncomingMeeting.meetid,
          date: new Date(oncomingMeeting.date.seconds * 1000),
          subject: oncomingMeeting.subject
        });
      }
    });

    state.isFullLoading = false;
  },
  initializeCentrifuge({ state, rootState, dispatch }) {
    state.isWebSocketConnecting = true;

    const startConnect = Date.now();

    state.webSocket = new WebSocket(
      rootState["settingsStore"].developerMode,
      () => {
        const endConnect = Date.now();

        if (
          rootState["settingsStore"].developerMode &&
          state.isWebSocketConnecting
        ) {
          // eslint-disable-next-line no-console
          console.log(
            `%c${new Date().toLocaleString()}: %cCentrifuge%c Connection time: %c${(
              endConnect - startConnect
            ).toLocaleString()}ms`,
            "color: initial;font-style: italic",
            "color: #49a1ff;font-weight: bold;",
            "color: initial;font-weight: bold;",
            "color: #e11b11;font-weight: bold;"
          );
        }

        state.isWebSocketConnecting = false;
      }
    );

    state.webSocket.setPublishHandler(message => {
      dispatch("notification", message);
    });

    state.webSocket.centrifuge?.on("error", error => {
      dispatch("error", error);
    });

    state.webSocket.centrifuge?.on("connecting", () => {
      state.isWebSocketOnline = false;
    });

    state.webSocket.centrifuge?.on("connected", () => {
      state.isWebSocketOnline = true;
    });

    if (rootState.user) {
      state.webSocket.connect(rootState.user.id);
    }
  },
  async publish({ dispatch, state }, message: Centrifuge.IMessage) {
    const stream = state.streamList.find(s => s.id === message.stream_id);

    if (stream) {
      dispatch("subscribeCallback", { message, stream });
    }
  },
  notification({ dispatch }, notification: Centrifuge.Notification) {
    const handlerNames: (string | null)[] = [
      null, //Alert
      "Invite",
      "Subscribe",
      "Unsubscribe",
      "MyRead",
      null, //InterlocutorRead
      "UpdateStream",
      "UpdateProfile",
      "UpdateUser",
      null, //UpdateContact v2
      null, //MessageTyping
      null, //UpdateDeiceId
      null, //UpdateSettings
      "UpdateContact",
      "Call",
      "Message",
      "OncomingMeet"
    ];

    if (handlerNames[notification.type]) {
      const token = localStorage.getItem(LSNames.AT);

      service.groupLog(
        [
          `%c${new Date().toLocaleString()}: %cCentrifuge%c: %c${handlerNames[notification.type]}`,
          "color: initial;font-style: italic",
          "color: #49a1ff;font-weight: bold;",
          "color: initial;font-weight: bold;",
          "color: #e11b11;font-weight: bold;"
        ],
        {
          Notification: { ...notification },
          Session: token
            ? jwtDecode<{ session_id?: string }>(token).session_id
            : undefined
        }
      );
      dispatch("notification" + handlerNames[notification.type], notification);
    }
  },
  error({ state }, context: any) {
    if (state.errorHandler) {
      state.errorHandler(context);
    }
  },
  disconnect({ state }) {
    if (state.webSocket) {
      state.webSocket.disconnect();
      state.webSocket = null;
    }
  },
  disconnected({ state }, context: any) {
    if (state.disconnectHandler) {
      state.disconnectHandler(context);
    }
  },
  removeStream({ state }, stream: Stream) {
    const streamIndex = state.streamList.findIndex(s => s.id === stream.id);

    if (streamIndex !== -1) {
      state.streamList.splice(streamIndex, 1);
    }
  },
  appendStream({ state }, stream: Stream) {
    const streamIndex = state.streamList.findIndex(s => s.id === stream.id);

    if (streamIndex === -1) {
      state.streamList.push(stream);
    }
  },
  updateStream({ state }, stream: Stream) {
    const streamIndex = state.streamList.findIndex(s => s.id === stream.id);

    if (streamIndex === -1) {
      state.streamList.push(stream);
    } else {
      state.streamList.splice(streamIndex, 1, stream);
    }
  },
  clearAllData({ state }) {
    state.webSocket = null;
    state.streamList = [];
    state.errorHandler = null;
    state.disconnectHandler = null;
    state.isLoading = false;
    state.users = [];
  },
  addUsers({ state }, userList: IUser[]) {
    userList.forEach(newUser => {
      const sameUserIndex = state.users.findIndex(
        u => u.portalCode === newUser.portalCode && u.id === newUser.id
      );

      if (sameUserIndex === -1) {
        state.users.push(newUser);
      }
    });
  },
  appendMessage({ state, rootState, dispatch }, message: IMessage) {
    const stream = state.streamList.find(s => s.id === message.streamId);

    if (stream) {
      const muted = stream.getChatMutedState(
        (rootState as IStore).notificationsStore
      );

      if (
        rootState.user?.id !== message.author?.id &&
        message.type &&
        !muted &&
        (rootState.currentChatId !== message.streamId ||
          !rootState.isAppFocused)
      ) {
        // const webClickHandler = () => {
        //   router.push({
        //     name: "chat-page",
        //     params: {
        //       id: stream.id
        //     }
        //   });
        // };
        service.notificationHandler(
          stream,
          message,
          rootState.contactList || []
          // webClickHandler
        );
      }
      stream.pushNewMessage(
        message,
        state.users,
        rootState.user,
        rootState.contactList || []
      );
      dispatch("updateBadgeCount");
    }
  },
  updateBadgeCount({ state }) {
    const streamList: Stream[] = state.streamList;

    service.updateBadgeCount(
      streamList.reduce(
        (previousValue, currentValue) =>
          previousValue +
          (currentValue.lastMessageId !== currentValue.lastReadMessageId &&
          currentValue.lastMessageId
            ? 1
            : 0),
        0
      )
    );
  },
  async notificationSubscribe(
    { rootState, state, dispatch },
    notification: Centrifuge.Notification
  ) {
    const user = rootState.user;
    const { sub_unsub } = notification;

    if (sub_unsub && user && sub_unsub.user_id === user.id) {
      try {
        if (state.streamList.some(s => s.id === sub_unsub.stream_id)) {
          return;
        }
        const streamResponse = await Infra.stream.GetStream({
          streamId: sub_unsub.stream_id
        });
        const stream = streamService.streamsAdapter(
          streamResponse,
          user,
          state.users,
          rootState.contactList || []
        );
        let messageResponse: Message.AsObject[] = [];

        const updateDate = new Date();

        try {
          messageResponse = await Infra.stream.GetMessageList({
            streamId: stream.id,
            updateDate
          });
        } catch {}

        if (messageResponse.length) {
          const messages = messageResponse.map(m =>
            streamService.messageAdapter(
              m,
              user.id,
              state.users,
              user,
              rootState.contactList || [],
              stream
            )
          );
          const lastMessage = messages[messages.length - 1];

          stream.messages = messages;
          stream.lastMessageId = lastMessage.id;
          stream.lastMessageAuthorName = streamService.getLastMessageAuthor(
            stream.type,
            stream.interlocutorId,
            lastMessage,
            state.users,
            user,
            rootState.contactList || []
          );
          stream.lastMessageBody = streamService.getChatBody(lastMessage);
          stream.lastMessageDate = lastMessage.date;
          stream.lastMessageDateString = streamService.getChatTime(
            stream.createDate,
            lastMessage.date
          );
          stream.lastReadMessageId = lastMessage.id;
        }
        dispatch("appendStream", stream);
      } catch {}
    } else {
      if (sub_unsub?.user) {
        const users = [
          streamService.userAdapter(
            streamService.notificationUserAdapterRaw(sub_unsub.user)
          )
        ];

        dispatch("addUsers", users);
        const stream = state.streamList.find(s => s.id === sub_unsub.stream_id);

        if (stream) {
          stream.addParticipants(users);
        }
      }
    }
  },
  notificationUnsubscribe(
    { state, rootState },
    notification: Centrifuge.Notification
  ) {
    const { sub_unsub } = notification;

    if (sub_unsub) {
      if (sub_unsub.user_id === rootState.user?.id) {
        const streamIndex = state.streamList.findIndex(
          s => s.id === sub_unsub.stream_id
        );

        if (streamIndex !== -1) {
          state.streamList.splice(streamIndex, 1);
        }
      } else {
        const stream = state.streamList.find(s => s.id === sub_unsub.stream_id);

        if (stream) {
          stream.removeParticipant(sub_unsub.user_id);
        }
      }
    }
  },
  notificationUpdateContact({ commit }, notification: Centrifuge.Notification) {
    commit("updateContact", notification, { root: true });
  },
  notificationUpdateProfile({ commit }, notification: Centrifuge.Notification) {
    commit("updateProfile", notification, { root: true });
  },
  notificationMyRead({ state }, notification: Centrifuge.Notification) {
    const { read } = notification;

    if (read) {
      const stream = state.streamList.find(s => s.id === read.stream_id);

      if (stream) {
        stream.lastReadMessageId = read.message_id;
      }
    }
  },
  notificationCall(
    { rootState, dispatch },
    notification: Centrifuge.Notification
  ) {
    const { call } = notification;

    if (call && rootState.user) {
      const callType = call.call_type;
      const waitingCallId: string = rootState.waitingCallWindow
        ? rootState.waitingCallWindow.room
        : "";
      const room = call.room_id;
      const isOnMeetPage = service.isOnMeetPage();
      const isAuthorMe = call.user_id === rootState.user.id;

      if (
        [
          CallType.REJECT,
          CallType.END,
          CallType.ANSWER,
          CallType.CALL_TIMEOUT,
          CallType.CANCELED_CALL
        ].includes(callType) &&
        waitingCallId === room
      ) {
        if (
          callType === CallType.ANSWER &&
          call.user_id !== rootState.user.id
        ) {
          dispatch("meet", { roomId: room, force: true }, { root: true });
          dispatch("setWaitingCallWindow", null, { root: true });
        } else {
          dispatch("setWaitingCallRejected", true, { root: true });
        }
      }
      if (!isOnMeetPage) {
        if (call.is_group) {
          if (isAuthorMe) {
            if (
              [
                CallType.ANSWER,
                CallType.REJECT,
                CallType.END,
                CallType.CALL_TIMEOUT
              ].includes(callType)
            ) {
              dispatch(
                "setIncomingCallWindow",
                {
                  room,
                  call: null
                },
                { root: true }
              );
            }
          } else if ([CallType.END, CallType.CALL_TIMEOUT].includes(callType)) {
            dispatch(
              "setIncomingCallWindow",
              {
                room,
                call: null
              },
              { root: true }
            );
          }
        } else {
          if (isAuthorMe) {
            if (
              [
                CallType.ANSWER,
                CallType.REJECT,
                CallType.END,
                CallType.CALL_TIMEOUT,
                CallType.CANCELED_CALL
              ].includes(callType)
            ) {
              dispatch(
                "setIncomingCallWindow",
                {
                  room,
                  call: null
                },
                { root: true }
              );
            }
          } else if (
            [
              CallType.END,
              CallType.CALL_TIMEOUT,
              CallType.CANCELED_CALL
            ].includes(callType)
          ) {
            dispatch(
              "setIncomingCallWindow",
              {
                room,
                call: null
              },
              { root: true }
            );
          }
        }
      }
      if (callType === CallType.START) {
        if (call.user_id !== rootState.user.id) {
          const incomingCall: IIncomingCall = {
            subject: call.subject,
            participantCount: call.participants_count,
            participants: call.participants.map(p => ({
              name: p.name,
              avatarUrl: p.avatar_url
            })),
            group: call.is_group,
            room
          };

          dispatch(
            "setIncomingCallWindow",
            {
              room,
              call: incomingCall
            },
            { root: true }
          );
          if (!rootState.isAppFocused) {
            service.callNotification(
              call.subject,
              call.participants[0]?.name,
              call.is_group
            );
          }
        }
      }
      if (
        room === isOnMeetPage &&
        rootState.forceLeavePageHandler &&
        (
          callType === CallType.END ||
          (
            callType === CallType.LEAVE &&
            isAuthorMe &&
            call.device_id === rootState.deviceID
          )
        )
      ) {
        setTimeout(() => {
          rootState.forceLeavePageHandler?.();
        }, 1000);
      }
    }
  },
  async notificationInvite(
    { state, rootState, dispatch },
    notification: Centrifuge.Notification
  ) {
    const { invite } = notification;
    const user = rootState.user;

    if (invite?.stream?.stream) {
      const streamIndex = state.streamList.findIndex(
        s => s.id === invite.stream_id
      );

      if (streamIndex !== -1) {
        return;
      }

      if (
        invite.stream.stream.meta?.interlocutor &&
        invite.stream.stream.meta.interlocutor !== nullUuid
      ) {
        try {
          const newUser = await Infra.user.GetUserInfoById(
            invite.stream.stream.meta.interlocutor
          );

          dispatch("addUsers", [streamService.userAdapter(newUser)]);
        } catch {}
      }

      const stream = streamService.streamsAdapter(
        streamService.notificationStreamAdapterRaw(invite.stream.stream),
        user!,
        state.users,
        rootState.contactList || [],
        invite.active_meet?.room_id
      );

      let messageResponse: Message.AsObject[] = [];

      if (invite.stream.last_message_id) {
        try {
          messageResponse = await Infra.stream.GetMessageList({
            streamId: stream.id,
            messageId: invite.stream.last_message_id
          });
        } catch {}
      }

      if (messageResponse.length && user) {
        const messages = messageResponse.map(m =>
          streamService.messageAdapter(
            m,
            user.id,
            state.users,
            user,
            rootState.contactList || [],
            stream
          )
        );
        const lastMessage = messages[messages.length - 1];

        stream.messages = messages;
        stream.lastMessageId = lastMessage.id;
        stream.lastMessageAuthorName = streamService.getLastMessageAuthor(
          stream.type,
          stream.interlocutorId,
          lastMessage,
          state.users,
          user,
          rootState.contactList || []
        );
        stream.lastMessageBody = streamService.getChatBody(lastMessage);
        stream.lastMessageDate = lastMessage.date;
        stream.lastMessageDateString = streamService.getChatTime(
          stream.createDate,
          lastMessage.date
        );
        stream.lastReadMessageId = lastMessage.id;
      }

      dispatch("appendStream", stream);
    }
  },
  async notificationUpdateUser(
    { state, rootState },
    notification: Centrifuge.Notification
  ) {
    const contacts: IUser[] = rootState.contactList || [];
    const { user } = notification;

    if (user?.user) {
      const userIndex = state.users.findIndex(u => u.id === user.user_id);

      if (userIndex) {
        const newUser = streamService.userAdapter(
          streamService.notificationUserAdapterRaw(user.user)
        );
        const contact = contacts.find(c => c.id === user.user_id);

        if (contact) {
          newUser.renderName = contact.renderName;
        }
        state.users.splice(userIndex, 1, newUser);
        const stream = state.streamList.find(
          s => s.interlocutorId === user.user_id
        );

        if (stream) {
          stream.iconUuid = user.user.avatar?.id || nullUuid;
          stream.iconUrl = user.user.avatar?.photo_large_url;
        }
      }
    }
  },
  notificationUpdateStream(
    { state, rootState },
    notification: Centrifuge.Notification
  ) {
    const updatedStream = notification.stream;

    if (updatedStream) {
      const streamIndex = state.streamList.findIndex(
        s => s.id === updatedStream.stream_id
      );

      if (streamIndex !== -1 && updatedStream.stream?.stream) {
        const stream = state.streamList[streamIndex];
        const newStream = streamService.streamsAdapter(
          streamService.notificationStreamAdapterRaw(
            updatedStream.stream.stream
          ),
          rootState.user!,
          state.users,
          rootState.contactList || [],
          updatedStream.active_meet?.room_id,
          updatedStream.ongoing_meet
        );

        newStream.messages = stream.messages;
        newStream.lastMessageId = stream.lastMessageId;
        newStream.lastReadMessageId = stream.lastReadMessageId;
        newStream.lastMessageAuthorName = stream.lastMessageAuthorName;
        newStream.lastMessageBody = stream.lastMessageBody;
        newStream.lastMessageDate = stream.lastMessageDate;
        newStream.lastMessageDateString = stream.lastMessageDateString;

        state.streamList.splice(streamIndex, 1, newStream);
      }
    }
  },
  notificationMessage({ dispatch }, notification: Centrifuge.Notification) {
    if (notification.message) {
      dispatch("publish", notification.message);
    }
  },
  notificationOncomingMeet({ state }, notification: Centrifuge.Notification) {
    if (notification.oncoming_meet) {
      const stream = state.streamList.find(
        s => s.id === notification.oncoming_meet?.stream_id
      );

      if (stream) {
        stream.setOncomingMeet({
          meetId: notification.oncoming_meet.meet_id,
          date: new Date(notification.oncoming_meet.date),
          subject: notification.oncoming_meet.subject
        });
      }
      service.showPush(
        notification.oncoming_meet.subject,
        `Встреча через ${notification.oncoming_meet.remind_in} ${service.declOfNum(notification.oncoming_meet.remind_in, ["минуту", "минуты", "минут"])}`,
        "oncoming-meet",
        undefined,
        undefined,
        "desktop",
        notification.oncoming_meet
      );
    }
  },
  updateLastMessage({ state, dispatch }, updatedStream: Stream) {
    const stream = state.streamList.find(s => s.id === updatedStream.id);

    if (stream) {
      stream.lastMessageId = updatedStream.lastMessageId;
      stream.lastMessageBody = updatedStream.lastMessageBody;
      stream.lastReadMessageId = updatedStream.lastReadMessageId;
      stream.lastMessageDate = updatedStream.lastMessageDate;
      stream.lastMessageDateString = updatedStream.lastMessageDateString;
      stream.lastMessageAuthorName = updatedStream.lastMessageAuthorName;
      stream.messages = updatedStream.messages;
    } else {
      dispatch("appendStream", updatedStream);
    }
  },
  updateUser({ state }, user: IUser) {
    const userIndex = state.users.findIndex(
      u => u.portalCode === user.portalCode
    );

    state.users.splice(
      userIndex,
      1,
      service.updateUser(state.users[userIndex], user)
    );
  },
  addUserColleagues(
    { state },
    payload: { portalCode: number; colleagues: IColleagues }
  ) {
    const stateUser = state.users.find(
      u => u.portalCode === payload.portalCode
    );
    const user: IUser | undefined = stateUser ? { ...stateUser } : undefined;
    const userIndex = state.users.findIndex(
      u => u.portalCode === payload.portalCode
    );

    if (user && userIndex !== -1) {
      user.subordinates = payload.colleagues.subordinates;
      user.coworkers = payload.colleagues.coworkers;
      user.funcLeader = payload.colleagues.funcLeader;
      user.admLeader = payload.colleagues.admLeader;
      state.users.splice(userIndex, 1, user);
    }
  },
  async subscribeCallback(
    { state, rootState, dispatch },
    payload: { message: Centrifuge.IMessage; stream: Stream }
  ) {
    if (
      !state.users.some(u => u.id === payload.message.user_id) &&
      payload.message.user_id !== nullUuid
    ) {
      try {
        const user = await Infra.user.GetUserInfoById(payload.message.user_id);

        dispatch("addUsers", [streamService.userAdapter(user)]);
      } catch {}
    }
    if (rootState.user) {
      dispatch(
        "appendMessage",
        streamService.messageAdapter(
          streamService.notificationMessageAdapterRaw(payload.message),
          rootState.user.id,
          state.users,
          rootState.user,
          rootState.contactList || [],
          payload.stream
        )
      );
    }
  }
} as ActionTree<IStreamStore, IState>;
