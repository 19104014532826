import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import { LSNames } from "@/common/constants";
import { finalMiddleware } from "@/middleware/common/final-middleware";
import router from "@/router";

export async function authPageMiddleware(
  _: RouteLocationNormalized,
  __: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  if (localStorage.getItem(LSNames.AT)) {
    await router.push({ name: "index" });
  } else {
    await finalMiddleware();
    next();
  }
}
