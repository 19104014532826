import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "popup-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.popupList, (popupItem) => {
      return (_openBlock(), _createBlock(_component_Popup, {
        key: popupItem.index,
        popup: popupItem,
        visible: !popupItem.hidden,
        onClose: ($event: any) => (_ctx.closePopup(popupItem))
      }, null, 8 /* PROPS */, ["popup", "visible", "onClose"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}