import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dropdown-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TNDropdown = _resolveComponent("TNDropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TNDropdown, {
      class: "dropdown-menu__dropdown",
      style: _normalizeStyle([{"position":"absolute"}, _ctx.style]),
      "is-visible": _ctx.showMenu,
      options: _ctx.menuItems,
      shrink: false,
      "onClick:outside": _ctx.closeOptionsMenu,
      onSelect: _ctx.selectMenu
    }, null, 8 /* PROPS */, ["is-visible", "options", "style", "onClick:outside", "onSelect"])
  ]))
}