import { IService, IServiceUUIDs } from "@/components/sidebar/i-sidebar";

export default function (uuids: IServiceUUIDs): IService[] {
  return [
    ...(uuids.finApp
      ? [
          {
            name: "Финансовый помощник",
            path: "/app/" + uuids.finApp,
            iconName: "finhelper-2-colored"
          } as IService
        ]
      : []),
    ...(uuids.activeTN
      ? [
          {
            name: "Активный сотрудник",
            path: "/app/" + uuids.activeTN,
            iconName: "activeemp-colored"
          } as IService
        ]
      : []),
    ...(uuids.knowledgeBase
      ? [
          {
            name: "База&nbsp;знаний",
            path: "/app/" + uuids.knowledgeBase,
            iconName: "wiki-colored"
          } as IService
        ]
      : []),
    ...(uuids.projectOffice
      ? [
          {
            name: "Проектный офис",
            path: "/app/" + uuids.projectOffice,
            iconName: "projects-colored"
          } as IService
        ]
      : []),
    ...(uuids.directives
      ? [
          {
            name: "Распоряжения",
            path: "/app/" + uuids.directives,
            iconName: "order-colored"
          } as IService
        ]
      : []),
    ...(uuids.phoneBook
      ? [
          {
            name: "Телефонная книга",
            path: "/app/" + uuids.phoneBook,
            hide: true,
            iconName: "user-light"
          } as IService
        ]
      : [])
  ];
}
