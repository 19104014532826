import { format } from "date-fns";
import { ru } from "date-fns/locale/ru";

import { sixWeeks } from "@/common/constants";
import { IMessage } from "@/store/modules/stream/i-stream";

export const random = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min)) + min;

export const getMessageBody = (
  message: IMessage,
  notification?: boolean
): string => {
  let attachmentText = message.documents?.length
    ? `<b>[${message.documents[0].name}]</b> `
    : message.images.length === 1
      ? "<b>[Изображение]</b> "
      : message.images.length > 1
        ? "<b>[Изображения]</b> "
        : message.contact
          ? "<b>[Контакт]</b> "
          : message.reply
            ? "<b>[Ответ]</b> "
            : message.forward
              ? "<b>[Сообщение]</b> "
              : "";

  if (notification) {
    attachmentText = attachmentText.replaceAll(/<\/?b\/?>/g, "");
  }

  return attachmentText + message.body;
};

export const formatLastUpdatedAt = (date: Date): string => {
  const today: Date = new Date();
  const todayMidnight = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  if (todayMidnight.getTime() < date.getTime()) {
    return format(date, "HH:mm");
  } else if (todayMidnight.getTime() - date.getTime() < sixWeeks) {
    return format(date, "EEEEEE", { locale: ru });
  } else if (todayMidnight.getFullYear() === date.getFullYear()) {
    return format(date, "dd.MM");
  }

  return format(date, "MM.yyyy");
};
